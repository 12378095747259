import { z } from "zod";

import { mfaDeliveryMethodSchema } from "./mfaDeliveryMethodSchema";

export const promptOptionSchema = z.object({
  label: z.string(),
  value: z.string(),
  hint: z.union([z.string(), z.null()]).optional(),
  mfa_method: z.union([z.lazy(() => mfaDeliveryMethodSchema), z.null()]).optional(),
});
export type PromptOptionSchema = z.infer<typeof promptOptionSchema>;
