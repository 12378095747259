import { createContext, useContext } from "react";

export type BackButtonControlProps = {
  showReturnToClient: boolean;
  showBackButton: boolean;
  goBackCallback: () => void;
};

export type BackButtonContextProps = {
  goBackControl: BackButtonControlProps;
  setBackButtonControl: (props: BackButtonControlProps) => void;
};

export const BackButtonContext = createContext<BackButtonContextProps | undefined>(undefined);

export function useBackButton(): BackButtonContextProps {
  const context = useContext(BackButtonContext);
  if (context === undefined) {
    throw new Error("useBackButton must be used within a BackButtonProvider");
  }
  return context;
}
