import { z } from "zod";

import { httpValidationErrorSchema } from "./httpValidationErrorSchema";
import { initializeBodySchema } from "./initializeBodySchema";
import { initializeResponseSchema } from "./initializeResponseSchema";

/**
 * @description Successful Response
 */
export const postInitialize200Schema = z.lazy(() => initializeResponseSchema);
export type PostInitialize200Schema = z.infer<typeof postInitialize200Schema>;
/**
 * @description Validation Error
 */
export const postInitialize422Schema = z.lazy(() => httpValidationErrorSchema);
export type PostInitialize422Schema = z.infer<typeof postInitialize422Schema>;

export const postInitializeMutationRequestSchema = z.lazy(() => initializeBodySchema);
export type PostInitializeMutationRequestSchema = z.infer<typeof postInitializeMutationRequestSchema>;
/**
 * @description Successful Response
 */
export const postInitializeMutationResponseSchema = z.lazy(() => initializeResponseSchema);
export type PostInitializeMutationResponseSchema = z.infer<typeof postInitializeMutationResponseSchema>;
