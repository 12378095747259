import * as Sentry from "@sentry/react";

import CloseWarning from "@/components/CloseWarning";
import SyncErrorScreen from "@/components/Exception/SyncErrorScreen";
import SyncDriver from "@/components/SyncMessages/SyncDriver";
import { WebSocketQueueProvider } from "@/contexts/WebSocketQueueContext";
import useSyncStore from "@/store/sync";
import { UUID } from "@/types";

export default function Sync() {
  const { sync_target_id } = useSyncStore();

  return (
    <Sentry.ErrorBoundary fallback={<SyncErrorScreen />}>
      <WebSocketQueueProvider syncTargetId={sync_target_id as UUID}>
        <CloseWarning />
        <SyncDriver />
      </WebSocketQueueProvider>
    </Sentry.ErrorBoundary>
  );
}
