import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FormEvent, useEffect, useRef, useState } from "react";

import api from "@/api/api";
import { Button } from "@/components/Buttons/Button";
import GoBackButton from "@/components/Buttons/GoBackButton";
import ContentHeader from "@/components/ContentHeader";
import { PasscodeInput } from "@/components/PasscodeInput";
import { defaultErrorMessage } from "@/constants/constants";
import { useBackButton } from "@/contexts/BackButtonContext";
import { postVerifyMutationRequestSchema } from "@/gen";
import useStateNavigate from "@/hooks/useStateNavigate";
import ContentBody from "@/pages/template/ContentBody";
import ContentFooter from "@/pages/template/ContentFooter";
import useAuthStore from "@/store/auth";
import useUserStore from "@/store/user";
import { parseFormData } from "@/utils";

function VerifyEmail() {
  const { navigateNext, navigateBack } = useStateNavigate();
  const { profile, setProfile } = useUserStore();
  const { authenticationStatus, setAuthenticationStatus } = useAuthStore();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const { setBackButtonControl } = useBackButton();

  const onInput = (ev: FormEvent<HTMLFormElement>) => {
    setIsFormValid(ev.currentTarget.checkValidity());
  };

  const verifyMutation = useMutation({
    mutationFn: async (passcode: string) => {
      const validated = postVerifyMutationRequestSchema.parse({ passcode });
      const verifyResp = await api.postVerify(validated);
      const serviceLinesResp = await api.getServiceLines({ queries: { is_healthy: true } });
      setProfile({
        ...profile,
        verified: true,
        authorized: verifyResp.is_authorized ?? false,
        serviceLines: serviceLinesResp.service_lines,
      });
      setAuthenticationStatus({ ...authenticationStatus, emailAuthenticated: true });
    },
    onSuccess: () => navigateNext(),
    onError: (error) => {
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          setErrorMessage("This passcode is invalid or has expired.");
          return;
        }
      }
      setErrorMessage(defaultErrorMessage);
    },
  });

  const onSubmit = (ev: FormEvent<HTMLFormElement>): void => {
    ev.preventDefault();
    setErrorMessage(null);
    const data = parseFormData(new FormData(ev.currentTarget));
    verifyMutation.mutate(data.code.toString());
  };

  const postResendMutation = useMutation({
    mutationFn: async () => api.postResend(undefined),
    onMutate: () => setErrorMessage(null),
    onError: () => setErrorMessage("An unexpected error occurred."),
  });

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setBackButtonControl({ showReturnToClient: true, showBackButton: true, goBackCallback: navigateBack });
  }, [navigateBack, setBackButtonControl]);

  return (
    <>
      <ContentBody>
        <ContentHeader title="Verify your identity" />
        <div className="mt-4 md:mt-10">
          <p className="text-md md:text-lg">
            Please enter the code sent to <span className="font-bold">{profile.user.emailAddress}</span>.
          </p>
        </div>
        <div className="mt-4 md:mt-10">
          <p className="text-md font-normal md:text-lg">
            {`Didn't receive a code? `}
            <button
              disabled={verifyMutation.isPending || postResendMutation.isPending}
              onClick={() => postResendMutation.mutate()}
              className="bg-transparent hover:text-gray-700 focus:outline-none"
            >
              <span className="text-primary font-medium underline underline-offset-2">Send a new code.</span>
            </button>
          </p>
        </div>
        <form
          className="replay-redact mt-4 md:mt-10"
          ref={formRef}
          onInput={(ev) => void onInput(ev)}
          onSubmit={(ev) => void onSubmit(ev)}
        >
          <PasscodeInput
            inputName="code"
            inputLength={6}
            regex={"[0-9a-zA-Z]"}
            error={errorMessage}
          />
          <button
            type="submit"
            className="hidden"
          />
        </form>
      </ContentBody>
      <ContentFooter>
        <Button
          label="Continue"
          primary
          fullWidth
          disabled={!isFormValid || verifyMutation.isPending || postResendMutation.isPending}
          loading={verifyMutation.isPending}
          onClick={() => formRef?.current?.requestSubmit()}
        />
        <div className="w-full max-md:hidden">
          <GoBackButton onClick={() => navigateBack()} />
        </div>
      </ContentFooter>
    </>
  );
}

export default VerifyEmail;
