import { z } from "zod";

export const initializeBodySchema = z.object({
  client_id: z.string().uuid(),
  scope: z.string(),
  code_challenge: z.string(),
  redirect_uri: z.string(),
  state: z.string(),
  init_token: z.string(),
});
export type InitializeBodySchema = z.infer<typeof initializeBodySchema>;
