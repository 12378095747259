import BaseModel from "@/models/base";

export enum InstructionType {
  Title = "title",
  Subtitle = "subtitle",
  Text = "text",
  AccountDetails = "account-details",
}

export interface AccountDetail extends BaseModel {
  key: string;
  value: string;
  copyable: boolean;
}

export interface Instruction extends BaseModel {
  instructionType: InstructionType;
  text: string | null;
  details: AccountDetail[] | null;
}
