import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, DragEvent, ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import api from "@/api/api";
import { uploadResource } from "@/api/connectApi";
import { FileRow } from "@/components/Form/FileUpload/FileRow";
import { ResourceFromOrmSchema } from "@/gen";
import { useGetResources } from "@/hooks/useApi";
import { FileTransferItem, Progress } from "@/interfaces";
import { PromptResponseType } from "@/models/prompt";

interface FileUploadProps {
  syncTargetId: string;
  promptId: string;
}

export function FileUpload({ syncTargetId, promptId }: FileUploadProps): ReactElement {
  const [files, setFiles] = useState<FileTransferItem[]>([]);
  const xhrRefs = useRef<{ [key: string]: XMLHttpRequest }>({});
  const { data: resources } = useGetResources(syncTargetId, promptId);

  const fileItems = useMemo((): FileTransferItem[] => {
    if (resources == null) return [];
    return resources.map(
      (resource: ResourceFromOrmSchema): FileTransferItem => ({
        file: {
          ...resource,
          file_id: uuidv4(),
          prompt_id: promptId,
        },
        isUploading: false,
        progress: { current: 100, total: 100 },
      }),
    );
  }, [resources, promptId]);

  useEffect(() => {
    setFiles(fileItems ?? []);
    document.querySelector("form")?.dispatchEvent(new Event("input", { bubbles: true }));
  }, [fileItems, setFiles]);

  function setInputValidity(input: HTMLInputElement, isValid: boolean): void {
    // manipulate input validity to disable/renable submit button when uploading
    input.setCustomValidity(isValid ? "" : "uploading");
    document.querySelector("form")?.dispatchEvent(new Event("input", { bubbles: true }));
  }

  async function uploadFiles(files: FileList): Promise<void> {
    const newFiles: FileTransferItem[] = Array.from(files).map((file) => ({
      file: {
        resource_id: "",
        prompt_id: promptId,
        mime_type: file.type,
        name: file.name,
        data: file,
        file_id: uuidv4(),
      },
      isUploading: true,
      progress: { current: 0, total: file.size },
    }));

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);

    const onUploadProgress = (fileId: string, progress: Progress) => {
      setFiles((prevFiles) => prevFiles.map((f) => (f.file?.file_id === fileId ? { ...f, progress } : f)));
    };

    const uploadPromises = newFiles.map(async (fileItem) => {
      try {
        const file = fileItem.file;
        if (!file) throw new Error("file undefined");
        const { resourceId } = await uploadResource(file, promptId, syncTargetId, xhrRefs, onUploadProgress);
        const updatedFile: FileTransferItem = {
          file: {
            ...file,
            resource_id: resourceId,
          },
          isUploading: false,
          progress: { current: file?.data?.size ?? 0, total: file?.data?.size ?? 0 },
        };
        setFiles((prevFiles) => prevFiles.map((f) => (f.file?.file_id === fileItem.file?.file_id ? updatedFile : f)));
      } catch (error) {
        setFiles((prevFiles) =>
          prevFiles.map((f) =>
            f.file?.file_id === fileItem.file?.file_id ? { ...f, isUploading: false, error: error as string } : f,
          ),
        );
      }
    });

    await Promise.all(uploadPromises);
  }

  async function handleFileUpload(event: ChangeEvent<HTMLInputElement>): Promise<void> {
    if (!event.target.files) return;
    setInputValidity(event.target, false);
    await uploadFiles(event.target.files);
    setInputValidity(event.target, true);
    event.target.value = "";
  }

  async function handleDrop(event: DragEvent<HTMLDivElement>): Promise<void> {
    event.preventDefault();
    const input = document.getElementById("file-upload") as HTMLInputElement;
    if (input) {
      setInputValidity(input, false);
      await uploadFiles(event.dataTransfer.files);
      setInputValidity(input, true);
    }
    event.dataTransfer.clearData();
  }

  function handleCancelUpload(fileId: string): void {
    xhrRefs.current[fileId]?.abort();
    delete xhrRefs.current[fileId];
    setFiles((prevFiles) => prevFiles.filter((f) => f.file?.file_id !== fileId));
  }

  const deleteResourceMutation = useMutation({
    mutationFn: async ({
      syncTargetId,
      resourceId,
      promptId,
    }: {
      syncTargetId: string;
      resourceId: string;
      promptId: string;
    }) => {
      await api.deleteResource(undefined, {
        params: { sync_target_id: syncTargetId, resource_id: resourceId },
        queries: { prompt_id: promptId },
      });
    },
    onSuccess: (_, { resourceId }) => {
      setFiles((prevFiles) => prevFiles.filter((file) => file.file?.resource_id !== resourceId));
    },
    throwOnError: true,
  });

  function handleRemoveFile(resourceId: string) {
    deleteResourceMutation.mutate({ syncTargetId, resourceId, promptId });
    setFiles((prevFiles) => prevFiles.filter((file) => file.file?.resource_id !== resourceId));
  }

  return (
    <div>
      <input
        type="file"
        className="hidden"
        id="file-upload"
        data-testid="file-upload"
        multiple
        onChange={(ev) => void handleFileUpload(ev)}
      />
      <label
        htmlFor="file-upload"
        className="text-sm font-semibold text-gray-600"
      >
        <div
          className="cursor-pointer rounded-lg border-2 border-dashed border-gray-300 p-12 text-center"
          onDragOver={(e) => e.preventDefault()}
          onDrop={(ev) => void handleDrop(ev)}
        >
          <CloudArrowUpIcon className="mx-auto mb-2 h-12 w-12 text-gray-400" />
          <span className="underline">Choose a file</span> or Drag and drop
          <p className="mt-2 text-sm text-gray-400">Supported file types: PDF</p>
        </div>
      </label>
      <p className="mt-4 text-center text-xs text-gray-400">
        Uploaded documents are protected with end-to-end encryption
      </p>
      <div className="mt-4 space-y-4">
        {files.map((fileItem, index) => (
          <FileRow
            key={index}
            item={fileItem}
            handleRemoveFile={handleRemoveFile}
            handleCancelUpload={handleCancelUpload}
          />
        ))}
        <input
          type="hidden"
          name={promptId}
          value={JSON.stringify(files.map((f) => ({ resource_id: f.file?.resource_id })))}
          data-response-type={PromptResponseType.Resource}
        />
      </div>
    </div>
  );
}
