import { useLocation } from "react-router-dom";

import ErrorAlert from "@/components/Exception/ErrorAlert";
import FetchAccountsSummary from "@/components/Results/FetchAccountsSummary";
import OngoingConnectivitySummary from "@/components/Results/OngoingConnectivitySummary";
import { BasicSyncResultSchema } from "@/gen";
import { useGetSyncResult, useGetSyncResults } from "@/hooks/useApi";

interface SyncResultSummaryViewProps {
  syncResult: BasicSyncResultSchema;
  showOngoingConnectivitySummary?: boolean;
}

export function SyncResultSummaryView({
  syncResult,
  showOngoingConnectivitySummary = false,
}: SyncResultSummaryViewProps) {
  return (
    <div className="flex w-full flex-col rounded border border-navy-100 p-2 md:p-4">
      <h2 className="text-md mb-1 font-bold text-navy-500 md:mb-2 md:text-xl">{syncResult.platform.name}</h2>
      <div>
        <div className="flex flex-col gap-y-2 md:gap-y-4">
          <FetchAccountsSummary syncResult={syncResult} />
          {showOngoingConnectivitySummary && <OngoingConnectivitySummary syncResult={syncResult} />}
        </div>
      </div>
    </div>
  );
}

interface SyncResultSummaryProps {
  syncTargetId: string;
}

export function SyncResultSummary({ syncTargetId }: SyncResultSummaryProps) {
  const location = useLocation();

  const { data: result, isPending, isError } = useGetSyncResult(syncTargetId);

  return isPending ? (
    <SyncResultSummaryLoadingSkeleton />
  ) : isError ? (
    <ErrorAlert description="We are unable to display your results." />
  ) : (
    <div className="flex w-full flex-col items-center justify-center">
      <SyncResultSummaryView
        syncResult={result}
        showOngoingConnectivitySummary={
          result.sync_target_parameters.create_user &&
          result.platform.subuser_strategy !== "unsupported" &&
          location.pathname === "/summary"
        }
      />
    </div>
  );
}

export function SyncResultSummaries() {
  const { data: results, isPending, isError } = useGetSyncResults();

  return isPending ? (
    <SyncResultSummaryLoadingSkeleton />
  ) : isError ? (
    <ErrorAlert description="We are unable to display your results." />
  ) : (
    <div className="flex h-full w-full flex-col items-center justify-start gap-y-4">
      {(results || []).map((result, i) => (
        <SyncResultSummaryView
          key={i}
          syncResult={result}
          showOngoingConnectivitySummary={
            result.sync_target_parameters.create_user && result.platform.subuser_strategy !== "unsupported"
          }
        />
      ))}
    </div>
  );
}

export function SyncResultSummaryLoadingSkeleton() {
  return (
    <div className="flex w-full animate-pulse flex-col justify-between space-y-6 rounded border-1 border-navy-100 p-4 py-6">
      <div className="h-4 w-48 rounded-full bg-navy-100" />
      <div className="flex flex-row space-x-4">
        <div className="h-5 w-5 rounded-full bg-navy-100" />
        <div className="flex flex-col space-y-4">
          <div className="mt-1 h-3 w-72 rounded-full bg-navy-100" />
          <div className="h-2 w-36 rounded-full bg-navy-100" />
        </div>
      </div>
      <div className="flex flex-row space-x-4">
        <div className="h-5 w-5 rounded-full bg-navy-100" />
        <div className="flex flex-col space-y-4">
          <div className="mt-1 h-3 w-56 rounded-full bg-navy-100" />
          <div className="h-2 w-64 rounded-full bg-navy-100" />
          <div className="h-2 w-24 rounded-full bg-navy-100" />
        </div>
      </div>
    </div>
  );
}
