import { z } from "zod";

import { postAuthorizeResponseSchema } from "./postAuthorizeResponseSchema";

/**
 * @description Successful Response
 */
export const postAuthorize200Schema = z.lazy(() => postAuthorizeResponseSchema);
export type PostAuthorize200Schema = z.infer<typeof postAuthorize200Schema>;
/**
 * @description Successful Response
 */
export const postAuthorizeMutationResponseSchema = z.lazy(() => postAuthorizeResponseSchema);
export type PostAuthorizeMutationResponseSchema = z.infer<typeof postAuthorizeMutationResponseSchema>;
