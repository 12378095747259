import { z } from "zod";

import { httpValidationErrorSchema } from "./httpValidationErrorSchema";
import { serviceLinesResponseSchema } from "./serviceLinesResponseSchema";

export const getServiceLinesQueryParamsSchema = z
  .object({ is_healthy: z.union([z.boolean(), z.null()]).describe("Query for service line health").optional() })
  .optional();
export type GetServiceLinesQueryParamsSchema = z.infer<typeof getServiceLinesQueryParamsSchema>;
/**
 * @description Successful Response
 */
export const getServiceLines200Schema = z.lazy(() => serviceLinesResponseSchema);
export type GetServiceLines200Schema = z.infer<typeof getServiceLines200Schema>;
/**
 * @description Validation Error
 */
export const getServiceLines422Schema = z.lazy(() => httpValidationErrorSchema);
export type GetServiceLines422Schema = z.infer<typeof getServiceLines422Schema>;
/**
 * @description Successful Response
 */
export const getServiceLinesQueryResponseSchema = z.lazy(() => serviceLinesResponseSchema);
export type GetServiceLinesQueryResponseSchema = z.infer<typeof getServiceLinesQueryResponseSchema>;
