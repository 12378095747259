import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import { Profile } from "@/interfaces";

interface UserStore {
  profile: Profile;
  setProfile: (profile: Profile) => void;
}

export const useUserStore = create<UserStore>()(
  devtools(
    persist(
      (set) => ({
        // Initial state.
        profile: {
          user: {
            emailAddress: "",
            name: null,
          },
          serviceLines: [],
          verified: false,
          authorized: false,
        },
        // Actions.
        setProfile: (profile) => set({ profile }),
      }),
      { name: "user" },
    ),
    {
      name: "connect",
      storage: sessionStorage,
    },
  ),
);

export default useUserStore;
