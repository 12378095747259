import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import { ClientConfigSchema } from "@/gen";

interface ClientStore {
  config: ClientConfigSchema;
  setConfig: (config: ClientConfigSchema) => void;
}

export const useClientStore = create<ClientStore>()(
  devtools(
    persist(
      (set) => ({
        // Initial state.
        config: {
          client_id: "",
          customer_short_name: "",
          customer_full_name: "",
          customer_logo: "",
          trust_email: false,
          land_header: null,
          land_subtitle: null,
          land_body: null,
          land_service_info: null,
          land_cta: null,
          summary_title: null,
          summary_subtitle: null,
          summary_body: null,
          summary_cta: null,
          accent_fill_color: null,
          accent_text_color: null,
          default_platform_slugs: null,
        },
        // Actions.
        setConfig: (config) => set({ config }),
      }),
      { name: "client" },
    ),
    { name: "connect", storage: sessionStorage },
  ),
);

export default useClientStore;
