import { CheckCircleIcon, ClockIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { ReactElement } from "react";

import { BasicAccountSchema, BasicSyncResultSchema } from "@/gen";

interface FetchAccountsSummaryProps {
  syncResult: BasicSyncResultSchema;
}

export default function FetchAccountsSummary({ syncResult }: FetchAccountsSummaryProps): ReactElement {
  const { accounts, subuser_authorization, subuser_request } = syncResult;

  const subuserRequestNoSubuser = subuser_request != null && subuser_authorization == null;
  const subuserCreatedNoAccountData = subuser_authorization && accounts.length === 0;
  const getAccountRepr = (idx: number, acct: BasicAccountSchema): string =>
    [acct.display_name, acct.account_mask && `****${acct.account_mask}`].filter((e) => e).join(" ") || `Account ${idx}`;

  return accounts.length ? (
    <div className="flex flex-col">
      <div className="flex flex-row items-center space-x-2.5">
        <CheckCircleIcon className="h-6 w-6 text-green-500" />
        <span className="text-sm font-semibold text-navy-500 md:text-lg">Account data retrieved</span>
      </div>
      <div className="ml-8 pl-0.5 text-sm text-navy-300 md:text-lg">
        {accounts.map((a, i) => (
          <div key={i}>{getAccountRepr(i, a)}</div>
        ))}
      </div>
    </div>
  ) : (
    <div className="flex flex-col">
      <div className="flex flex-row items-center space-x-2.5">
        {subuserRequestNoSubuser || subuserCreatedNoAccountData ? (
          <ClockIcon className="h-6 w-6 text-amber-500" />
        ) : (
          <ExclamationCircleIcon className="h-6 w-6 text-red-600" />
        )}
        <span className="text-sm font-semibold text-navy-500 md:text-lg">
          {subuserRequestNoSubuser || subuserCreatedNoAccountData
            ? "Account data pending"
            : "Account data not retrieved"}
        </span>
      </div>
      <div className="ml-8 pl-0.5 text-sm text-navy-300 md:text-lg">
        {`Your account data was not retrieved${
          subuserRequestNoSubuser || subuserCreatedNoAccountData
            ? ", but we will retry later. No further action is necessary."
            : ". Please add another account or try again later."
        }`}
      </div>
    </div>
  );
}
