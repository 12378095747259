import * as Sentry from "@sentry/react";
import { AnimatePresence, motion } from "motion/react";
import { ReactNode } from "react";
import { RouterProvider, createBrowserRouter, useLocation, useOutlet } from "react-router-dom";

import AboutBasis from "@/components/AboutBasis";
import AppErrorFallback from "@/components/Exception/AppErrorFallback";
import HeaderBar from "@/components/Layout/HeaderBar";
import { ENTRY_PATH } from "@/constants/constants";
import ConnectedServiceLines from "@/pages/ConnectedServiceLines";
import InstitutionSearch from "@/pages/InstitutionSearch";
import LandingPage from "@/pages/Land";
import NotFound from "@/pages/NotFound";
import Platform from "@/pages/Platform";
import Summary from "@/pages/Summary";
import Sync from "@/pages/Sync";
import VerifyEmail from "@/pages/VerifyEmail";
import BackButtonProvider from "@/providers/BackButtonProvider";
import QueryProvider from "@/providers/QueryProvider";
import ThemeProvider from "@/providers/ThemeProvider";

function Providers({ children }: { children: ReactNode }) {
  return (
    <QueryProvider>
      <ThemeProvider>
        <BackButtonProvider>{children}</BackButtonProvider>
      </ThemeProvider>
    </QueryProvider>
  );
}

function Template() {
  const location = useLocation();
  const content = useOutlet();
  return (
    <div className="flex h-[100dvh] w-screen flex-col antialiased">
      <HeaderBar />
      <div className="flex h-full min-h-0 w-full grow">
        <div className="absolute bottom-0 m-6 max-md:hidden">
          <AboutBasis header="logo" />
        </div>
        <div className="flex h-full w-full grow flex-col items-center overflow-y-auto">
          <div className="flex max-h-full w-full max-w-xl flex-col py-6 max-md:p-4 md:pt-24">
            <Sentry.ErrorBoundary fallback={<AppErrorFallback />}>
              <AnimatePresence mode="wait">
                <motion.div
                  className="h-full w-full"
                  key={location.key}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={{
                    initial: { opacity: 0, y: 15 },
                    animate: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        duration: 0.3,
                        ease: [0.25, 0.1, 0.25, 1],
                      },
                    },
                    exit: {
                      opacity: 0,
                      y: -5,
                      transition: {
                        duration: 0.2,
                        ease: [0.25, 0.1, 0.25, 1],
                      },
                    },
                  }}
                >
                  {content}
                </motion.div>
              </AnimatePresence>
            </Sentry.ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
}

const router = Sentry.wrapCreateBrowserRouter(createBrowserRouter)([
  {
    element: (
      <Providers>
        <Sentry.ErrorBoundary fallback={<AppErrorFallback />}>
          <Template />
        </Sentry.ErrorBoundary>
      </Providers>
    ),
    children: [
      {
        path: ENTRY_PATH,
        element: <LandingPage />,
      },
      {
        path: "verify",
        element: <VerifyEmail />,
      },
      {
        path: "connected",
        element: <ConnectedServiceLines />,
      },
      {
        path: "platforms",
        element: <InstitutionSearch />,
      },
      {
        path: "platforms/:platformId",
        element: <Platform />,
      },
      {
        path: "sync",
        element: <Sync />,
      },
      {
        path: "summary",
        element: <Summary />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
