import { useMemo } from "react";

import useClientStore from "@/store/client";

function getColorWithOpacity(color: string, opacity: number) {
  color = color.replace("#", "");

  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  const newR = Math.round(r * opacity + 255 * (1 - opacity));
  const newG = Math.round(g * opacity + 255 * (1 - opacity));
  const newB = Math.round(b * opacity + 255 * (1 - opacity));

  return (
    "#" + newR.toString(16).padStart(2, "0") + newG.toString(16).padStart(2, "0") + newB.toString(16).padStart(2, "0")
  );
}

interface Colors {
  accentFillColor: string;
  accentFillColorMuted: string;
  accentTextColor: string;
  accentTextColorMuted: string;
}

export function useColors(): Colors {
  const {
    config: { accent_fill_color, accent_text_color },
  } = useClientStore();

  return useMemo(() => {
    const defaults = {
      accentFillColor: "#84C268",
      accentTextColor: "#000000",
    };

    return {
      accentFillColor: accent_fill_color ?? defaults.accentFillColor,
      accentFillColorMuted: getColorWithOpacity(accent_fill_color ?? defaults.accentFillColor, 0.1),
      accentTextColor: accent_text_color ?? defaults.accentTextColor,
      accentTextColorMuted: getColorWithOpacity(accent_text_color ?? defaults.accentTextColor, 0.3),
    };
  }, [accent_fill_color, accent_text_color]);
}
