import { getAPI } from "@/gen/zodios";

// TODO 2024-11-18: once we have fully migrated to generated API client usage, we can change this
// environment variable to omit the trailing /api.
const base =
  import.meta.env.VITE_REACT_APP_BASIS_API_URL == null
    ? ""
    : import.meta.env.VITE_REACT_APP_BASIS_API_URL.endsWith("/api")
      ? import.meta.env.VITE_REACT_APP_BASIS_API_URL.slice(
          undefined,
          import.meta.env.VITE_REACT_APP_BASIS_API_URL.length - 4,
        )
      : import.meta.env.VITE_REACT_APP_BASIS_API_URL;
const api = getAPI(base);

api.axios.defaults.withCredentials = true;
api.axios.defaults.paramsSerializer = { indexes: null };

export default api;
