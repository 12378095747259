import { ReactElement } from "react";

import useClientStore from "@/store/client";
import { convertBase64SVGToImageUrl } from "@/utils";

export default function CustomerLogo(): ReactElement | null {
  const {
    config: { customer_logo },
  } = useClientStore();

  return customer_logo ? (
    <img
      className="h-6 w-auto"
      src={convertBase64SVGToImageUrl(customer_logo)}
      alt="Client logo"
    />
  ) : null;
}
