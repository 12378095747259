import { z } from "zod";

import { basicAccountSchema } from "./basicAccountSchema";
import { basicAuthorizationSchema } from "./basicAuthorizationSchema";
import { basicPlatformSchema } from "./basicPlatformSchema";
import { basicSubuserRequestSchema } from "./basicSubuserRequestSchema";
import { basicSyncTargetParametersSchema } from "./basicSyncTargetParametersSchema";

export const getSyncResultResponseSchema = z.object({
  sync_target_id: z.string().uuid(),
  subuser_authorization: z.union([z.lazy(() => basicAuthorizationSchema), z.null()]),
  subuser_request: z.union([z.lazy(() => basicSubuserRequestSchema), z.null()]),
  platform: z.lazy(() => basicPlatformSchema),
  accounts: z.array(z.lazy(() => basicAccountSchema)),
  sync_target_parameters: z.lazy(() => basicSyncTargetParametersSchema),
});
export type GetSyncResultResponseSchema = z.infer<typeof getSyncResultResponseSchema>;
