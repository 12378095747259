import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { ReactElement } from "react";

import { Button } from "@/components/Buttons/Button";
import ContentHeader from "@/components/ContentHeader";
import { PaddedPlatformLogo } from "@/components/PlatformLogo";
import useStateNavigate from "@/hooks/useStateNavigate";
import ContentBody from "@/pages/template/ContentBody";
import ContentFooter from "@/pages/template/ContentFooter";
import useClientStore from "@/store/client";
import useSyncStore from "@/store/sync";

export default function SyncErrorScreen(): ReactElement {
  const { navigateBack } = useStateNavigate();
  const { platformConfig: platform } = useSyncStore();
  const {
    config: { customer_short_name },
  } = useClientStore();

  return (
    <div className="flex h-full w-full flex-col items-center">
      <ContentBody>
        <div className="flex flex-row items-center">
          <ExclamationCircleIcon className="mr-4 h-10 w-10 fill-red-500 stroke-white" />
          <ContentHeader title="Something went wrong." />
        </div>
        <div className="mt-4 md:mt-10">
          <p>We were unable to connect your account. You can retry below, or you can connect to another bank.</p>
        </div>
        <div className="mt-4 md:mt-10">
          <p>You can also return to {customer_short_name} and try again later.</p>
        </div>
        {platform != null && (
          <PaddedPlatformLogo
            logo={platform.logo}
            displayName={platform.display_name}
          />
        )}
      </ContentBody>
      <ContentFooter>
        <Button
          type="submit"
          label="Retry"
          primary
          fullWidth
          onClick={() => navigateBack()}
        />
      </ContentFooter>
    </div>
  );
}
