import { ReactElement, ReactNode, useState } from "react";

import MobileInformationDrawer from "@/components/MobileInformationDrawer";
import PoweredByBasis from "@/components/PoweredByBasis";

export interface ContentFooterProps {
  children: ReactNode;
}

export default function ContentFooter({ children }: ContentFooterProps): ReactElement {
  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState<boolean>(false);

  return (
    <div className="mt-2 w-full shrink-0 md:mt-6">
      <div className="flex flex-col gap-y-2 md:gap-y-6">
        {children}
        <div className="flex w-full flex-row items-center justify-center max-md:hidden">
          <PoweredByBasis />
        </div>
        <button
          className="flex w-full flex-row items-center justify-center md:hidden"
          onClick={() => setIsMobileDrawerOpen(true)}
        >
          <PoweredByBasis />
        </button>
        <MobileInformationDrawer
          isOpen={isMobileDrawerOpen}
          onClose={() => setIsMobileDrawerOpen(false)}
        />
      </div>
    </div>
  );
}
