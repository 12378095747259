import { ReactElement, useState } from "react";

interface MobileShowMoreProps {
  text: string;
}

export default function MobileShowMoreExpander({ text }: MobileShowMoreProps): ReactElement {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  return (
    <span>
      {isExpanded ? (
        <span>{text}</span>
      ) : (
        <button
          className="underline"
          onClick={() => setIsExpanded(true)}
        >
          Show more
        </button>
      )}
    </span>
  );
}
