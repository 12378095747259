import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReactElement } from "react";

interface ErrorAlertProps {
  description?: string | ReactElement;
  remediation?: string | ReactElement;
  includeContact?: boolean;
  onClose?: () => void;
}

function ErrorAlert({
  description = "An error occurred.",
  remediation = "Please try again.",
  includeContact = true,
  onClose,
}: ErrorAlertProps) {
  return (
    <div className="flex w-full flex-col border-1 border-red-300 bg-red-50 p-4">
      <div className="flex flex-row items-center justify-between">
        <div className="grow font-semibold">{description}</div>
        {onClose && (
          <XMarkIcon
            className="-mr-1.5 -mt-3 h-5 w-5 shrink-0 hover:cursor-pointer"
            onClick={onClose}
          />
        )}
      </div>
      <div>
        {remediation}
        {includeContact && (
          <>
            {" "}
            If the issue persists,{" "}
            <a
              href={"mailto:support@usebasis.co"}
              target="_blank"
              rel="noopener noreferrer"
              className="underline underline-offset-2 hover:text-gray-700"
            >
              contact us
            </a>
            .
          </>
        )}
      </div>
    </div>
  );
}

export default ErrorAlert;
