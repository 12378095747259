import { z } from "zod";

import { credentialTypeSchema } from "./credentialTypeSchema";
import { institutionPlatformAggregatorSchema } from "./institutionPlatformAggregatorSchema";
import { mfaDeliveryMethodSchema } from "./mfaDeliveryMethodSchema";
import { promptOptionSchema } from "./promptOptionSchema";
import { promptRoleSchema } from "./promptRoleSchema";
import { promptTypeSchema } from "./promptTypeSchema";

export const promptSchema = z.object({
  prompt_id: z.string(),
  label: z.string(),
  prompt_type: z.lazy(() => promptTypeSchema),
  role: z.lazy(() => promptRoleSchema).optional(),
  default_response: z.union([z.string(), z.null()]).optional(),
  challenge_pattern: z.union([z.string(), z.null()]).optional(),
  challenge_time: z.union([z.string().datetime({ local: true }), z.null()]).optional(),
  challenge_destination: z.union([z.string(), z.null()]).optional(),
  mfa_method: z.union([z.lazy(() => mfaDeliveryMethodSchema), z.null()]).optional(),
  credential_type: z.union([z.lazy(() => credentialTypeSchema), z.null()]).optional(),
  length: z.tuple([z.union([z.number().int(), z.null()]), z.union([z.number().int(), z.null()])]).optional(),
  options: z.union([z.array(z.lazy(() => promptOptionSchema)), z.null()]).optional(),
  interactive: z.boolean().default(true).optional(),
  assets: z.union([z.object({}).strict().catchall(z.string()), z.null()]).optional(),
  aggregator_type: z.union([z.lazy(() => institutionPlatformAggregatorSchema), z.null()]).optional(),
  aggregator_url: z.union([z.string(), z.null()]).optional(),
});
export type PromptSchema = z.infer<typeof promptSchema>;
