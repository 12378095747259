import { z } from "zod";

export const oAuthInitializeRequestBodySchema = z.object({
  client_id: z.union([z.string(), z.null()]).optional(),
  scope: z.union([z.string(), z.null()]).optional(),
  code_challenge: z.union([z.string(), z.null()]).optional(),
  response_type: z.union([z.string(), z.null()]).optional(),
  redirect_uri: z.union([z.string(), z.null()]).optional(),
  state: z.union([z.string(), z.null()]).optional(),
  token: z.union([z.string(), z.null()]).optional(),
});
export type OAuthInitializeRequestBodySchema = z.infer<typeof oAuthInitializeRequestBodySchema>;
