import { useMemo } from "react";

import useClientStore from "@/store/client";

export function useCopy() {
  const {
    config: {
      land_header,
      land_subtitle,
      land_body,
      land_service_info,
      land_cta,
      summary_title,
      summary_subtitle,
      summary_body,
      summary_cta,
      customer_short_name,
    },
  } = useClientStore();
  return useMemo(() => {
    const defaults = {
      landHeader: "Connect your account",
      landSubtitle: `${customer_short_name} partners with Basis for secure financial data sharing.`,
      landBody: `Basis will help you build a financial profile of your business so that ${customer_short_name} can 
                         serve you.`,
      landServiceInfo:
        "- Basic information about your business is used to create a profile and verify your identity.\n" +
        "- Basis assists you in connecting your business's bank accounts to your profile.\n" +
        `- ${customer_short_name} uses this information to serve you.`,
      landCTA: "Continue",
      summaryTitle: "Summary",
      summarySubtitle: "What's next",
      summaryCTA: `Authorize Access for ${customer_short_name}`,
      summaryRetryCTA: `Finish and return to ${customer_short_name}`,
    };

    return {
      landHeader: land_header ?? defaults.landHeader,
      landSubtitle: land_subtitle ?? defaults.landSubtitle,
      landBody: land_body ?? defaults.landBody,
      landServiceInfo: land_service_info ?? defaults.landServiceInfo,
      landCTA: land_cta ?? defaults.landCTA,
      summaryTitle: summary_title ?? defaults.summaryTitle,
      summarySubtitle: summary_subtitle ?? defaults.summarySubtitle,
      summaryBody: summary_body ?? null,
      summaryCTA: summary_cta ?? defaults.summaryCTA,
      summaryRetryCTA: summary_cta ?? defaults.summaryRetryCTA,
    };
  }, [
    land_header,
    land_subtitle,
    land_body,
    land_service_info,
    land_cta,
    summary_title,
    summary_subtitle,
    summary_body,
    summary_cta,
    customer_short_name,
  ]);
}
