import { CheckCircleIcon, ClockIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { ReactElement } from "react";

import MobileShowMoreExpander from "@/components/Results/MobileShowMoreExpander";
import { BasicSyncResultSchema } from "@/gen";

interface OngoingConnectivitySummaryProps {
  syncResult: BasicSyncResultSchema;
}

const limitedServiceContinued =
  "Please return at a later time to enable data refresh. You may need an account administrator to enable this.";

export default function OngoingConnectivitySummary({ syncResult }: OngoingConnectivitySummaryProps): ReactElement {
  const {
    platform: { name: platformName, subuser_strategy },
    subuser_authorization,
    subuser_request,
  } = syncResult;

  if (subuser_strategy === "unsupported") {
    return <div className="-mb-2 md:-mb-4"></div>;
  }

  return subuser_authorization ? (
    <div className="text-sm md:text-lg">
      <div className="flex flex-row items-center space-x-2.5">
        <CheckCircleIcon className="h-6 w-6 text-green-500" />
        <span className="font-semibold text-navy-500">Data refresh enabled</span>
      </div>
      <div className="ml-9 text-pretty text-navy-300">
        Your account is configured to stay up to date and share new transactions.
      </div>
    </div>
  ) : subuser_request ? (
    <div className="text-sm md:text-lg">
      <div className="flex flex-row items-center space-x-2.5">
        <ClockIcon className="h-6 w-6 text-amber-500" />
        <span className="font-semibold text-navy-500">Data refresh pending</span>
      </div>
      <div className="ml-9 text-pretty text-navy-300">
        Account data will be updated once the user you created is active. If you&apos;ve already created the user, no
        further action is necessary.
      </div>
    </div>
  ) : (
    <div className="text-sm md:text-lg">
      <div className="flex flex-row items-center space-x-2.5">
        <ExclamationCircleIcon className="h-6 w-6 text-red-600" />
        <span className="font-semibold text-navy-500">Data will not refresh</span>
      </div>
      <div className="ml-9 text-pretty text-navy-300">
        {`Your service at ${platformName} may be limited. `}
        <span className="md:hidden">
          <MobileShowMoreExpander text={limitedServiceContinued} />
        </span>
        <span className="max-md:hidden">{limitedServiceContinued}</span>
      </div>
    </div>
  );
}
