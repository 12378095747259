import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, DocumentIcon, ExclamationCircleIcon, TrashIcon } from "@heroicons/react/24/solid";
import { ReactElement, useState } from "react";

import { LoadingSpinner } from "@/components/Suspense/Loading";
import { FileTransferItem } from "@/interfaces";

interface FileRowProps {
  item: FileTransferItem;
  handleRemoveFile: (resourceId: string) => void;
  handleCancelUpload: (fileId: string) => void;
}

export function FileRow({ item, handleRemoveFile, handleCancelUpload }: FileRowProps): ReactElement {
  const [actionIsLoading, setActionIsLoading] = useState<boolean>(false);
  const progressPercentage =
    item.progress && item.progress.total > 0 ? (item.progress.current / item.progress.total) * 100 : 0;

  function removeFile() {
    if (!item.file?.resource_id) return;
    setActionIsLoading(true);
    handleRemoveFile(item.file?.resource_id);
    setActionIsLoading(false);
  }

  function cancelUpload(): void {
    if (!item.file?.file_id) return;
    setActionIsLoading(true);
    handleCancelUpload(item.file?.file_id);
    setActionIsLoading(false);
  }

  return (
    <div
      className="relative border"
      data-testid="file-row"
    >
      <div className="flex w-full items-center justify-between p-5">
        {item.isUploading ? (
          <DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-400" />
        ) : item.error ? (
          <ExclamationCircleIcon className="h-5 w-5 flex-shrink-0 text-red-500" />
        ) : (
          <CheckCircleIcon className="h-5 w-5 flex-shrink-0 text-green-500" />
        )}
        <span className="mx-4 flex-grow overflow-hidden truncate whitespace-nowrap text-gray-600">
          {item.file?.name}
        </span>
        <span className="ml-auto flex-shrink-0 truncate text-xs text-red-500">{item.error}</span>
        {item.isUploading ? (
          <span
            data-testid="cancel-upload"
            className="ml-2 flex-shrink-0 cursor-pointer text-xs text-gray-400"
            onClick={() => cancelUpload()}
          >
            Cancel
          </span>
        ) : item.error ? (
          <XMarkIcon
            data-testid="cancel-upload-error"
            className="ml-2 h-5 w-5 flex-shrink-0 cursor-pointer text-red-500"
            onClick={() => cancelUpload()}
          />
        ) : actionIsLoading ? (
          <LoadingSpinner size={5} />
        ) : (
          <TrashIcon
            data-testid="remove-file"
            className="ml-2 h-5 w-5 flex-shrink-0 cursor-pointer text-gray-400"
            onClick={() => removeFile()}
          />
        )}
      </div>
      <div className={`absolute -mt-1 w-full bg-gray-200 ${item.isUploading ? "h-1" : "h-0"}`}>
        <div
          className={`h-full ${item.error ? "bg-red-500" : "bg-accent-fill"}`}
          style={{
            width: `${progressPercentage}%`,
            transition: "width 0.2s ease-in-out",
          }}
        ></div>
      </div>
    </div>
  );
}
