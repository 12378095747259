import { z } from "zod";

export const promptRoleSchema = z.enum([
  "credentials",
  "security-question",
  "mfa-delivery-method",
  "mfa-passcode",
  "other",
]);
export type PromptRoleSchema = z.infer<typeof promptRoleSchema>;
