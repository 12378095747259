import { ReactElement, ReactNode, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { BackButtonContext, BackButtonControlProps } from "@/contexts/BackButtonContext";

const defaultProps: BackButtonControlProps = {
  showReturnToClient: true,
  showBackButton: false,
  goBackCallback: () => null,
};

function BackButtonProvider({ children }: { children: ReactNode }): ReactElement {
  const [goBackControl, setBackButtonControl] = useState<BackButtonControlProps>(defaultProps);
  const location = useLocation();

  //  TODO: 2024-09-24 - BAS-1397 - eliminate provider's dependence on router's location, allowing it to live above the router
  useEffect(() => {
    setBackButtonControl(defaultProps);
  }, [location.pathname]);

  const setBackButtonControlCallback = useCallback((props: BackButtonControlProps) => {
    setBackButtonControl(props);
  }, []);

  return (
    <BackButtonContext.Provider value={{ goBackControl, setBackButtonControl: setBackButtonControlCallback }}>
      {children}
    </BackButtonContext.Provider>
  );
}

export default BackButtonProvider;
