import { z } from "zod";

import { httpValidationErrorSchema } from "./httpValidationErrorSchema";
import { identifyBodySchema } from "./identifyBodySchema";
import { identifyResponseSchema } from "./identifyResponseSchema";

/**
 * @description Successful Response
 */
export const postIdentify200Schema = z.lazy(() => identifyResponseSchema);
export type PostIdentify200Schema = z.infer<typeof postIdentify200Schema>;
/**
 * @description Validation Error
 */
export const postIdentify422Schema = z.lazy(() => httpValidationErrorSchema);
export type PostIdentify422Schema = z.infer<typeof postIdentify422Schema>;

export const postIdentifyMutationRequestSchema = z.lazy(() => identifyBodySchema);
export type PostIdentifyMutationRequestSchema = z.infer<typeof postIdentifyMutationRequestSchema>;
/**
 * @description Successful Response
 */
export const postIdentifyMutationResponseSchema = z.lazy(() => identifyResponseSchema);
export type PostIdentifyMutationResponseSchema = z.infer<typeof postIdentifyMutationResponseSchema>;
