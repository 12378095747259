import { z } from "zod";

import { httpValidationErrorSchema } from "./httpValidationErrorSchema";
import { postVerifyBodySchema } from "./postVerifyBodySchema";
import { postVerifyResponseSchema } from "./postVerifyResponseSchema";

/**
 * @description Successful Response
 */
export const postVerify200Schema = z.lazy(() => postVerifyResponseSchema);
export type PostVerify200Schema = z.infer<typeof postVerify200Schema>;
/**
 * @description Validation Error
 */
export const postVerify422Schema = z.lazy(() => httpValidationErrorSchema);
export type PostVerify422Schema = z.infer<typeof postVerify422Schema>;

export const postVerifyMutationRequestSchema = z.lazy(() => postVerifyBodySchema);
export type PostVerifyMutationRequestSchema = z.infer<typeof postVerifyMutationRequestSchema>;
/**
 * @description Successful Response
 */
export const postVerifyMutationResponseSchema = z.lazy(() => postVerifyResponseSchema);
export type PostVerifyMutationResponseSchema = z.infer<typeof postVerifyMutationResponseSchema>;
