import { z } from "zod";

export const clientConfigSchema = z.object({
  client_id: z.string().uuid(),
  land_header: z.union([z.string(), z.null()]).optional(),
  land_subtitle: z.union([z.string(), z.null()]).optional(),
  land_body: z.union([z.string(), z.null()]).optional(),
  land_service_info: z.union([z.string(), z.null()]).optional(),
  land_cta: z.union([z.string(), z.null()]).optional(),
  summary_title: z.union([z.string(), z.null()]).optional(),
  summary_subtitle: z.union([z.string(), z.null()]).optional(),
  summary_body: z.union([z.string(), z.null()]).optional(),
  summary_cta: z.union([z.string(), z.null()]).optional(),
  accent_fill_color: z.union([z.string(), z.null()]).optional(),
  accent_text_color: z.union([z.string(), z.null()]).optional(),
  customer_short_name: z.union([z.string(), z.null()]).optional(),
  customer_full_name: z.union([z.string(), z.null()]).optional(),
  customer_logo: z.string(),
  trust_email: z.boolean().default(false).optional(),
  default_platform_slugs: z.union([z.array(z.string()), z.null()]).optional(),
  webhook_url: z.union([z.string(), z.null()]).optional(),
});
export type ClientConfigSchema = z.infer<typeof clientConfigSchema>;
