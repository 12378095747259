import { SyncError } from "@/models/error";
import { Instruction } from "@/models/instructions";
import { BaseSyncMessagePayload, SyncStage } from "@/models/message";
import { SimpleObservationType } from "@/models/observation";
import { AnyPromptResponse, Prompt } from "@/models/prompt";
import { UUID } from "@/types";

export enum SyncEventType {
  Start = "start",
  Report = "report",
  Prompt = "prompt",
  Response = "response",
  Error = "error",
  Decision = "decision",
  Choice = "choice",
  ResultReady = "result-ready",
  StageComplete = "stage-complete",
  SyncComplete = "sync-complete",
  Observe = "observe",
  Instructions = "instructions",
}

export enum UserDecisionType {
  CreateSubUser = "create-subuser",
}

export interface BaseSyncEventPayload extends BaseSyncMessagePayload {
  eventType: SyncEventType;
  eventId: UUID;
}

export enum ReportHint {
  NoMoreInteractiveSteps = "no-more-interactive-steps",
}

export interface ReportEventPayload extends BaseSyncEventPayload {
  eventType: SyncEventType.Report;
  eventId: UUID;
  stage: SyncStage[];
  summary: string;
  description: string | null;
  progress: [number, number] | null;
  hint: ReportHint | null;
}

export interface PromptEventPayload extends BaseSyncEventPayload {
  eventType: SyncEventType.Prompt;
  eventId: UUID;
  prompts: Prompt[];
  title: string | null;
  deadline: string | null;
}

export interface InstructionsEventPayload extends BaseSyncEventPayload {
  eventType: SyncEventType.Instructions;
  instructions: Instruction[];
  deadline: string | null;
}

export interface ResponseEventPayload extends BaseSyncEventPayload {
  eventType: SyncEventType.Response;
  eventId: UUID;
  responses: AnyPromptResponse[];
}

export interface DecisionEventPayload extends BaseSyncEventPayload {
  eventType: SyncEventType.Decision;
  eventId: UUID;
  decisionType: UserDecisionType;
  deadline: string | null;
}

export interface ChoiceEventPayload extends BaseSyncEventPayload {
  eventType: SyncEventType.Choice;
  eventId: UUID;
  choice: boolean;
  decisionType: UserDecisionType;
}

export interface SyncCompleteEventPayload extends BaseSyncEventPayload {
  eventType: SyncEventType.SyncComplete;
  eventId: UUID;
}

export interface StageCompleteEventPayload extends BaseSyncEventPayload {
  eventType: SyncEventType.StageComplete;
  eventId: UUID;
  stage: SyncStage[];
  success: boolean;
  error: SyncError | null;
  warnings: SyncError[];
}

export interface SimpleObserveEventPayload extends BaseSyncEventPayload {
  eventType: SyncEventType.Observe;
  eventId: UUID;
  observationType: SimpleObservationType;
}

export interface SimpleAccountsObservePayload extends SimpleObserveEventPayload {
  observationType: SimpleObservationType.Accounts;
  accounts: Array<{
    accountNumber: string;
    displayName: string;
    accountType: string;
  }>;
}

export interface ErrorEventPayload extends BaseSyncEventPayload {
  eventType: SyncEventType.Error;
  eventId: UUID;
  stage: SyncStage[];
  error: SyncError;
  recoverable: boolean;
}
