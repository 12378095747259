import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ReactElement, useEffect, useRef } from "react";

import api from "@/api/api";
import { Button } from "@/components/Buttons/Button";
import ContentHeader from "@/components/ContentHeader";
import RequestErrorMessage from "@/components/Exception/RequestErrorMessage";
import ServiceInfo from "@/components/ServiceInfo";
import ServicesHintDiagram from "@/components/ServicesHintDiagram";
import TermsOfService from "@/components/TermsOfService";
import ValueProposition from "@/components/ValueProposition";
import {
  oAuthHttpErrorSchema,
  postInitializeMutationRequestSchema,
  postOAuthInitializeMutationRequestSchema,
} from "@/gen";
import { useGetClientConfig } from "@/hooks/useApi";
import { useCopy } from "@/hooks/useCopy";
import useStateNavigate from "@/hooks/useStateNavigate";
import { OAuthParams } from "@/interfaces";
import ContentBody from "@/pages/template/ContentBody";
import ContentFooter from "@/pages/template/ContentFooter";
import useAuthStore from "@/store/auth";
import useClientStore from "@/store/client";
import useUserStore from "@/store/user";
import { InitVersion, handleBackToClient, parseInitToken, redirectToClient } from "@/utils";

export default function LandingPage(): ReactElement {
  const { navigateNext } = useStateNavigate();
  const { landHeader, landSubtitle, landBody, landServiceInfo, landCTA } = useCopy();
  const { authenticationStatus, setAuthenticationStatus, setOAuthParams } = useAuthStore();
  const { setConfig } = useClientStore();
  const { profile, setProfile } = useUserStore();
  const queryParams = new URLSearchParams(window.location.search);

  const hasInitializedRef = useRef<boolean>(false);

  const oAuthInitializeMutation = useMutation({
    mutationFn: async () => {
      if (queryParams.has("init_token")) {
        // legacy initialize flow
        console.warn("init_token is deprecated, use token instead");
        const validated = postInitializeMutationRequestSchema.parse(Object.fromEntries(queryParams.entries()));
        const initializeResp = await api.postInitialize(validated);

        // handle initialize redirect uri
        if (initializeResp.location != null) {
          redirectToClient(initializeResp.location);
        }
      } else if (queryParams.has("token")) {
        // new initialize flow
        const validated = postOAuthInitializeMutationRequestSchema.parse(Object.fromEntries(queryParams.entries()));
        const initializeResp = await api.postOAuthInitialize(validated);

        const { service_lines: serviceLines } = await api.getServiceLines({ queries: { is_healthy: true } });

        setProfile({
          ...profile,
          serviceLines,
          verified: true,
          authorized: initializeResp.is_authorized ?? false,
        });
      } else {
        throw new Error("One of init_token or token query parameter is required.");
      }

      // store oauth params
      const oauthParams: OAuthParams = {
        clientId: queryParams.get("client_id") ?? "",
        scope: queryParams.get("scope") ?? "",
        redirectUri: queryParams.get("redirect_uri") ?? "",
        state: queryParams.get("state") ?? "",
        codeChallenge: queryParams.get("code_challenge") ?? "",
        initToken: queryParams.get("init_token") ?? "",
        token: queryParams.get("token") ?? "",
      };
      setOAuthParams(oauthParams);

      setAuthenticationStatus({
        ...authenticationStatus,
        emailChallenged: false,
        emailAuthenticated: true,
      });
      hasInitializedRef.current = true;
    },
    onError: (err) => {
      if (err instanceof AxiosError) {
        const parsed = oAuthHttpErrorSchema.parse(err.response?.data);
        redirectToClient(parsed.uri);
      }
    },
    retry: false,
  });

  const { data: config, isPending: isConfigPending } = useGetClientConfig({
    enabled: oAuthInitializeMutation.isSuccess,
  });

  useEffect(() => {
    if (config) setConfig(config);
  }, [config, setConfig]);

  useEffect(() => {
    if (!hasInitializedRef.current && oAuthInitializeMutation.isIdle) oAuthInitializeMutation.mutate();
  }, [oAuthInitializeMutation, oAuthInitializeMutation.isIdle]);

  const identifyMutation = useMutation({
    mutationFn: async () => {
      const initTokenParams = parseInitToken(queryParams.get("init_token") ?? "");
      const identifyResp = await api.postIdentify({});

      const { service_lines: serviceLines } = await api.getServiceLines({ queries: { is_healthy: true } });

      setAuthenticationStatus({
        ...authenticationStatus,
        emailChallenged: true,
        emailAuthenticated: identifyResp.is_authenticated,
      });
      setProfile({
        user: {
          emailAddress: initTokenParams.userEmail,
          name: initTokenParams.userName,
        },
        serviceLines,
        verified: identifyResp.is_verified,
        authorized: identifyResp.is_authorized,
      });
    },
    onSuccess: () => navigateNext(),
    onError: async () => {
      if (queryParams.has("redirect_uri")) {
        const redirectUri = queryParams.get("redirect_uri");
        const state = queryParams.get("state");
        if (redirectUri !== null) {
          const version = queryParams.has("init_token") ? InitVersion.V1 : InitVersion.V2;
          await handleBackToClient(false, redirectUri, state, version);
          return;
        }
      }
    },
    retry: false,
  });

  const handleContinue = () => {
    if (queryParams.has("init_token")) {
      console.warn("init_token is deprecated, use token instead");
      identifyMutation.mutate();
    } else {
      navigateNext();
    }
  };

  return (
    <>
      <ContentBody>
        <ContentHeader title={landHeader} />
        <div className="mt-4 md:mt-10">
          <ValueProposition
            landSubtitle={landSubtitle}
            landBody={landBody}
          />
        </div>
        <div className="mt-10">
          <ServiceInfo content={landServiceInfo} />
        </div>
        <div className="mb-6 mt-10">
          <ServicesHintDiagram />
        </div>
      </ContentBody>
      <ContentFooter>
        <Button
          primary={true}
          fullWidth
          label={landCTA}
          onClick={handleContinue}
          loading={isConfigPending || identifyMutation.isPending || !hasInitializedRef.current}
          disabled={isConfigPending || identifyMutation.isPending || !hasInitializedRef.current}
        />
        {identifyMutation.isError && <RequestErrorMessage />}
        <TermsOfService />
      </ContentFooter>
    </>
  );
}
