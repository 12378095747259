import { z } from "zod";

import { httpValidationErrorSchema } from "./httpValidationErrorSchema";
import { postConnectSyncRequestSchema } from "./postConnectSyncRequestSchema";
import { postConnectSyncResponseSchema } from "./postConnectSyncResponseSchema";

/**
 * @description Successful Response
 */
export const postSync200Schema = z.lazy(() => postConnectSyncResponseSchema);
export type PostSync200Schema = z.infer<typeof postSync200Schema>;
/**
 * @description Validation Error
 */
export const postSync422Schema = z.lazy(() => httpValidationErrorSchema);
export type PostSync422Schema = z.infer<typeof postSync422Schema>;

export const postSyncMutationRequestSchema = z.lazy(() => postConnectSyncRequestSchema);
export type PostSyncMutationRequestSchema = z.infer<typeof postSyncMutationRequestSchema>;
/**
 * @description Successful Response
 */
export const postSyncMutationResponseSchema = z.lazy(() => postConnectSyncResponseSchema);
export type PostSyncMutationResponseSchema = z.infer<typeof postSyncMutationResponseSchema>;
