import { ReactElement } from "react";

import useClientStore from "@/store/client";

export default function TermsOfService(): ReactElement {
  const {
    config: { customer_short_name },
  } = useClientStore();
  return (
    <p className="text-pretty text-xs font-light text-navy-400">
      By continuing, you agree to our
      <a
        href="https://usebasis.co/"
        target="_blank"
        rel="noopener noreferrer"
        className="font-medium"
      >
        {` terms of service `}
      </a>
      and
      <a
        href="https://usebasis.co/"
        target="_blank"
        rel="noopener noreferrer"
        className="font-medium"
      >
        {` privacy policy `}
      </a>
      and authorize {customer_short_name} to access your financial data.
    </p>
  );
}
