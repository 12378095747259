import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import { SyncSessionParams } from "@/interfaces";
import { SyncError } from "@/models/error";

interface SyncStore extends SyncSessionParams {
  setSyncSession: (session: SyncSessionParams) => void;
  setError: (error: SyncError | null) => void;
  setTerminalError: (terminalError: SyncError | null) => void;
}

export const useSyncStore = create<SyncStore>()(
  devtools(
    persist(
      (set) => ({
        // Initial state.
        sync_target_id: "",
        platform_id: "",
        platformConfig: null,
        error: null,
        terminalError: null,
        // Actions.
        setSyncSession: (session) => set(session),
        setError: (error) => set({ error }),
        setTerminalError: (terminalError) => set({ terminalError }),
      }),
      { name: "sync" },
    ),
    {
      name: "connect",
      storage: sessionStorage,
    },
  ),
);

export default useSyncStore;
