import { Zodios, makeApi } from "@zodios/core";

import {
  deleteResource422Schema,
  deleteResourceMutationResponseSchema,
  deleteResourcePathParamsSchema,
  deleteResourceQueryParamsSchema,
} from "./zod/deleteResourceSchema";
import { getClientQueryResponseSchema } from "./zod/getClientSchema";
import {
  getPlatform422Schema,
  getPlatformPathParamsSchema,
  getPlatformQueryResponseSchema,
} from "./zod/getPlatformSchema";
import {
  getPlatforms422Schema,
  getPlatformsQueryParamsSchema,
  getPlatformsQueryResponseSchema,
} from "./zod/getPlatformsSchema";
import {
  getResources422Schema,
  getResourcesPathParamsSchema,
  getResourcesQueryParamsSchema,
  getResourcesQueryResponseSchema,
} from "./zod/getResourcesSchema";
import {
  getServiceLines422Schema,
  getServiceLinesQueryParamsSchema,
  getServiceLinesQueryResponseSchema,
} from "./zod/getServiceLinesSchema";
import {
  getSyncResult422Schema,
  getSyncResultPathParamsSchema,
  getSyncResultQueryResponseSchema,
} from "./zod/getSyncResultSchema";
import { getSyncResultsQueryResponseSchema } from "./zod/getSyncResultsSchema";
import { postAuthorizeMutationResponseSchema } from "./zod/postAuthorizeSchema";
import {
  postIdentify422Schema,
  postIdentifyMutationRequestSchema,
  postIdentifyMutationResponseSchema,
} from "./zod/postIdentifySchema";
import {
  postInitialize422Schema,
  postInitializeMutationRequestSchema,
  postInitializeMutationResponseSchema,
} from "./zod/postInitializeSchema";
import { postOAuthAuthorizeMutationResponseSchema } from "./zod/postOAuthAuthorizeSchema";
import {
  postOAuthInitialize400Schema,
  postOAuthInitialize422Schema,
  postOAuthInitializeMutationRequestSchema,
  postOAuthInitializeMutationResponseSchema,
} from "./zod/postOAuthInitializeSchema";
import { postResendMutationResponseSchema } from "./zod/postResendSchema";
import {
  postResource422Schema,
  postResourceMutationRequestSchema,
  postResourceMutationResponseSchema,
  postResourcePathParamsSchema,
} from "./zod/postResourceSchema";
import { postSync422Schema, postSyncMutationRequestSchema, postSyncMutationResponseSchema } from "./zod/postSyncSchema";
import {
  postVerify422Schema,
  postVerifyMutationRequestSchema,
  postVerifyMutationResponseSchema,
} from "./zod/postVerifySchema";

export const endpoints = makeApi([
  {
    method: "get",
    path: "/api/client",
    alias: "getClient",
    description: ``,
    requestFormat: "json",
    parameters: [],
    response: getClientQueryResponseSchema,
    errors: [],
  },
  {
    method: "get",
    path: "/api/service-lines",
    alias: "getServiceLines",
    description: ``,
    requestFormat: "json",
    parameters: [
      {
        name: "is_healthy",
        description: `Query for service line health`,
        type: "Query",
        schema: getServiceLinesQueryParamsSchema.unwrap().shape["is_healthy"],
      },
    ],
    response: getServiceLinesQueryResponseSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: getServiceLines422Schema,
      },
    ],
  },
  {
    method: "post",
    path: "/api/identify",
    alias: "postIdentify",
    description: `Takes in data from user shown below, and identifies if this is a 1) new email - new profile or 2)
existing profile - existing email. In the new case, send through verification process. In the existing
case, authenticate user.`,
    requestFormat: "json",
    parameters: [
      {
        name: "PostIdentifyMutationRequest",
        description: ``,
        type: "Body",
        schema: postIdentifyMutationRequestSchema,
      },
    ],
    response: postIdentifyMutationResponseSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: postIdentify422Schema,
      },
    ],
  },
  {
    method: "post",
    path: "/api/verify",
    alias: "postVerify",
    description: ``,
    requestFormat: "json",
    parameters: [
      {
        name: "PostVerifyMutationRequest",
        description: ``,
        type: "Body",
        schema: postVerifyMutationRequestSchema,
      },
    ],
    response: postVerifyMutationResponseSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: postVerify422Schema,
      },
    ],
  },
  {
    method: "post",
    path: "/api/resend",
    alias: "postResend",
    description: ``,
    requestFormat: "json",
    parameters: [],
    response: postResendMutationResponseSchema,
    errors: [],
  },
  {
    method: "post",
    path: "/api/authorize",
    alias: "postAuthorize",
    description: ``,
    requestFormat: "json",
    parameters: [],
    response: postAuthorizeMutationResponseSchema,
    errors: [],
  },
  {
    method: "post",
    path: "/api/initialize",
    alias: "postInitialize",
    description: `Takes in data from user shown below, and identifies if this is a 1) new email - new profile or 2)
existing profile - existing email. In the new case, send through verification process. In the existing
case, authenticate user.
headers:
- 'kid': \`key_id\`
payload:
- 'client_id'
- 'user_email'
- 'user_phone'
- 'user_name'
- 'company_ein'
- 'company_name'
- 'native_user_id'
- 'native_company_id'
- 'platform_id'
- 'profile_id'`,
    requestFormat: "json",
    parameters: [
      {
        name: "PostInitializeMutationRequest",
        description: ``,
        type: "Body",
        schema: postInitializeMutationRequestSchema,
      },
    ],
    response: postInitializeMutationResponseSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: postInitialize422Schema,
      },
    ],
  },
  {
    method: "post",
    path: "/api/oauth/initialize",
    alias: "postOAuthInitialize",
    description: ``,
    requestFormat: "json",
    parameters: [
      {
        name: "PostOAuthInitializeMutationRequest",
        description: ``,
        type: "Body",
        schema: postOAuthInitializeMutationRequestSchema,
      },
    ],
    response: postOAuthInitializeMutationResponseSchema,
    errors: [
      {
        status: 400,
        description: `Bad Request`,
        schema: postOAuthInitialize400Schema,
      },
      {
        status: 422,
        description: `Validation Error`,
        schema: postOAuthInitialize422Schema,
      },
    ],
  },
  {
    method: "post",
    path: "/api/oauth/authorize",
    alias: "postOAuthAuthorize",
    description: ``,
    requestFormat: "json",
    parameters: [],
    response: postOAuthAuthorizeMutationResponseSchema,
    errors: [],
  },
  {
    method: "get",
    path: "/api/platforms",
    alias: "getPlatforms",
    description: ``,
    requestFormat: "json",
    parameters: [
      {
        name: "text",
        description: ``,
        type: "Query",
        schema: getPlatformsQueryParamsSchema.unwrap().shape["text"],
      },
      {
        name: "slug",
        description: ``,
        type: "Query",
        schema: getPlatformsQueryParamsSchema.unwrap().shape["slug"],
      },
      {
        name: "limit",
        description: ``,
        type: "Query",
        schema: getPlatformsQueryParamsSchema.unwrap().shape["limit"],
      },
    ],
    response: getPlatformsQueryResponseSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: getPlatforms422Schema,
      },
    ],
  },
  {
    method: "get",
    path: "/api/platforms/:platform_path",
    alias: "getPlatform",
    description: ``,
    requestFormat: "json",
    parameters: [
      {
        name: "platform_path",
        description: ``,
        type: "Path",
        schema: getPlatformPathParamsSchema.shape["platform_path"],
      },
    ],
    response: getPlatformQueryResponseSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: getPlatform422Schema,
      },
    ],
  },
  {
    method: "get",
    path: "/api/sync/:sync_target_id/result",
    alias: "getSyncResult",
    description: ``,
    requestFormat: "json",
    parameters: [
      {
        name: "sync_target_id",
        description: ``,
        type: "Path",
        schema: getSyncResultPathParamsSchema.shape["sync_target_id"],
      },
    ],
    response: getSyncResultQueryResponseSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: getSyncResult422Schema,
      },
    ],
  },
  {
    method: "get",
    path: "/api/sync/results",
    alias: "getSyncResults",
    description: ``,
    requestFormat: "json",
    parameters: [],
    response: getSyncResultsQueryResponseSchema,
    errors: [],
  },
  {
    method: "post",
    path: "/api/sync",
    alias: "postSync",
    description: ``,
    requestFormat: "json",
    parameters: [
      {
        name: "PostSyncMutationRequest",
        description: ``,
        type: "Body",
        schema: postSyncMutationRequestSchema,
      },
    ],
    response: postSyncMutationResponseSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: postSync422Schema,
      },
    ],
  },
  {
    method: "get",
    path: "/api/sync/:sync_target_id/resources",
    alias: "getResources",
    description: ``,
    requestFormat: "json",
    parameters: [
      {
        name: "sync_target_id",
        description: ``,
        type: "Path",
        schema: getResourcesPathParamsSchema.shape["sync_target_id"],
      },
      {
        name: "prompt_id",
        description: ``,
        type: "Query",
        schema: getResourcesQueryParamsSchema.shape["prompt_id"],
      },
    ],
    response: getResourcesQueryResponseSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: getResources422Schema,
      },
    ],
  },
  {
    method: "post",
    path: "/api/sync/:sync_target_id/resources",
    alias: "postResource",
    description: ``,
    requestFormat: "json",
    parameters: [
      {
        name: "sync_target_id",
        description: ``,
        type: "Path",
        schema: postResourcePathParamsSchema.shape["sync_target_id"],
      },
      {
        name: "PostResourceMutationRequest",
        description: ``,
        type: "Body",
        schema: postResourceMutationRequestSchema,
      },
    ],
    response: postResourceMutationResponseSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: postResource422Schema,
      },
    ],
  },
  {
    method: "delete",
    path: "/api/sync/:sync_target_id/resources/:resource_id",
    alias: "deleteResource",
    description: ``,
    requestFormat: "json",
    parameters: [
      {
        name: "sync_target_id",
        description: ``,
        type: "Path",
        schema: deleteResourcePathParamsSchema.shape["sync_target_id"],
      },
      {
        name: "resource_id",
        description: ``,
        type: "Path",
        schema: deleteResourcePathParamsSchema.shape["resource_id"],
      },
      {
        name: "prompt_id",
        description: ``,
        type: "Query",
        schema: deleteResourceQueryParamsSchema.shape["prompt_id"],
      },
    ],
    response: deleteResourceMutationResponseSchema,
    errors: [
      {
        status: 422,
        description: `Validation Error`,
        schema: deleteResource422Schema,
      },
    ],
  },
]);
export const getAPI = (baseUrl: string) => new Zodios(baseUrl, endpoints);
export const api = new Zodios(endpoints);
export default api;
