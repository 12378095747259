import { z } from "zod";

import { oAuthAuthorizeResponseSchema } from "./oAuthAuthorizeResponseSchema";

/**
 * @description Successful Response
 */
export const postOAuthAuthorize200Schema = z.lazy(() => oAuthAuthorizeResponseSchema);
export type PostOAuthAuthorize200Schema = z.infer<typeof postOAuthAuthorize200Schema>;
/**
 * @description Successful Response
 */
export const postOAuthAuthorizeMutationResponseSchema = z.lazy(() => oAuthAuthorizeResponseSchema);
export type PostOAuthAuthorizeMutationResponseSchema = z.infer<typeof postOAuthAuthorizeMutationResponseSchema>;
