import { z } from "zod";

import { getPlatformResponseSchema } from "./getPlatformResponseSchema";
import { httpValidationErrorSchema } from "./httpValidationErrorSchema";

export const getPlatformPathParamsSchema = z.object({ platform_path: z.union([z.string(), z.string()]) });
export type GetPlatformPathParamsSchema = z.infer<typeof getPlatformPathParamsSchema>;
/**
 * @description Successful Response
 */
export const getPlatform200Schema = z.lazy(() => getPlatformResponseSchema);
export type GetPlatform200Schema = z.infer<typeof getPlatform200Schema>;
/**
 * @description Validation Error
 */
export const getPlatform422Schema = z.lazy(() => httpValidationErrorSchema);
export type GetPlatform422Schema = z.infer<typeof getPlatform422Schema>;
/**
 * @description Successful Response
 */
export const getPlatformQueryResponseSchema = z.lazy(() => getPlatformResponseSchema);
export type GetPlatformQueryResponseSchema = z.infer<typeof getPlatformQueryResponseSchema>;
