import { ReactElement, ReactNode } from "react";

import { useColors } from "@/hooks/useColors";

function ThemeProvider({ children }: { children: ReactNode }): ReactElement {
  const colors = useColors();
  return (
    <div
      className="h-full w-full"
      style={{
        ["--accent-fill-color" as string]: colors.accentFillColor,
        ["--accent-fill-color-muted" as string]: colors.accentFillColorMuted,
        ["--accent-text-color" as string]: colors.accentTextColor,
        ["--accent-text-color-muted" as string]: colors.accentTextColorMuted,
      }}
    >
      {children}
    </div>
  );
}

export default ThemeProvider;
