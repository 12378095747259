import { ArrowLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ReactElement } from "react";

import GoBackButton from "@/components/Buttons/GoBackButton";
import CustomerLogo from "@/components/CustomerLogo";
import { useBackButton } from "@/contexts/BackButtonContext";
import useAuthStore from "@/store/auth";
import useClientStore from "@/store/client";
import { InitVersion, handleBackToClient } from "@/utils";

export default function HeaderBar(): ReactElement {
  const {
    config: { customer_short_name },
  } = useClientStore();
  const { oauthParams } = useAuthStore();
  const {
    goBackControl: { showReturnToClient, showBackButton, goBackCallback },
  } = useBackButton();
  const version = oauthParams.initToken ? InitVersion.V1 : InitVersion.V2;

  return (
    <div className="flex w-full shrink-0 items-center justify-center border-b-1 border-neutral-300 py-2 text-sm">
      {showReturnToClient && (
        <button
          className="absolute left-2 flex flex-row items-center max-md:hidden"
          onClick={() => void handleBackToClient(false, oauthParams.redirectUri, oauthParams.state, version)}
        >
          <div className="flex items-center">
            <ArrowLeftIcon className="h-5 w-5 stroke-navy-500" />
            <span className="pl-2">Return to {customer_short_name}</span>
          </div>
        </button>
      )}
      {showBackButton && (
        <div className={`absolute left-2 md:hidden ${!showBackButton ? "hidden" : ""}`}>
          <GoBackButton onClick={() => goBackCallback()} />
        </div>
      )}
      <div>
        <CustomerLogo />
      </div>
      {showReturnToClient && (
        <button
          className="absolute right-2 md:hidden"
          onClick={() => void handleBackToClient(false, oauthParams.redirectUri, oauthParams.state, version)}
        >
          <XMarkIcon className="h-6 w-6 stroke-neutral-700" />
        </button>
      )}
    </div>
  );
}
