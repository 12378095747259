import { AxiosError } from "axios";
import { StatusCodes } from "http-status-codes";

import api from "@/api/api";
import { InitToken } from "@/interfaces";
import BaseModel from "@/models/base";
import { MajorSyncErrorType, MinorSyncErrorType, SyncError } from "@/models/error";

export function parseInitToken(initToken: string): InitToken {
  // convert to camel case
  const initTokenPayload = BaseModel.deserialize<InitToken>(atob(initToken.split(".")[1]));

  const token: InitToken = {
    ...initTokenPayload,
    nativeUserId: nullifyEmptyString(initTokenPayload.nativeUserId),
    nativeCompanyId: nullifyEmptyString(initTokenPayload.nativeCompanyId),
    userName: nullifyEmptyString(initTokenPayload.userName),
    userPhone: nullifyEmptyString(initTokenPayload.userPhone),
    companyEin: nullifyEmptyString(initTokenPayload.companyEin),
  };

  return token;
}

export enum InitVersion {
  V1 = "v1",
  V2 = "v2",
}

export async function handleBackToClient(
  userAuthorized: boolean,
  redirectUri: string,
  state: string | null,
  version: InitVersion, // TODO 2024-11-26: remove version after legacy initalization is removed
): Promise<void> {
  if (userAuthorized) {
    try {
      if (version === InitVersion.V1) {
        const authorizeResp = await api.postAuthorize({});
        window.location.href = authorizeResp.location;
      } else if (version === InitVersion.V2) {
        const authorizeResp = await api.postOAuthAuthorize({});
        window.location.href = authorizeResp.uri;
      } else {
        throw new Error("Invalid version");
      }
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === StatusCodes.UNAUTHORIZED) {
          const errorParams = new URLSearchParams({
            error: "access_denied",
            error_description: "user exited without granting access",
            error_uri: "https://www.ietf.org/archive/id/draft-ietf-oauth-v2-1-10.html#section-4.1.2.1",
            state: state ?? "",
          });
          window.location.href = `${redirectUri}?${errorParams.toString()}`;
        }
      }
      const errorParams = new URLSearchParams({
        error: "server_error",
        error_description: "an unknown error occurred; please try again",
        error_uri: "https://www.ietf.org/archive/id/draft-ietf-oauth-v2-1-10.html#section-4.1.2.1",
        state: state ?? "",
      });
      window.location.href = `${redirectUri}?${errorParams.toString()}`;
    }
  } else {
    const errorParams = new URLSearchParams({
      error: "access_denied",
      error_description: "user exited without granting access",
      error_uri: "https://www.ietf.org/archive/id/draft-ietf-oauth-v2-1-10.html#section-4.1.2.1",
      state: state ?? "",
    });
    window.location.href = `${redirectUri}?${errorParams.toString()}`;
  }
}

export function redirectToClient(uri: string) {
  window.location.href = uri;
}

export function convertBase64SVGToImageUrl(base64String: string): string {
  return `data:image/svg+xml;base64,${base64String}`;
}

export function parseFormData(formData: FormData): Readonly<{
  [key: string]: string;
}> {
  // TODO: 2024-08-27: don't assume the form inputs are strings
  return Object.fromEntries(Array.from(formData.entries()).map(([key, value]) => [key, String(value)])) as {
    [key: string]: string;
  };
}

export function secondsBetween(from: Date, to: Date): number {
  return (to.getTime() - from.getTime()) / 1000;
}

export function nullifyEmptyString(value: string | null | undefined): string | null {
  return value === "" || value == null ? null : value;
}

export function getSyncErrorMessage(error: SyncError): string {
  const defaultErrorMessage = "An unexpected error occurred.";
  switch (error.majorType) {
    case MajorSyncErrorType.User:
      switch (error.minorType) {
        case MinorSyncErrorType.InvalidCredentials:
          return "Your credentials were incorrect.";
        case MinorSyncErrorType.Timeout:
          return "We did not receive a response from you in time.";
        case MinorSyncErrorType.InvalidMFA:
          return "The code you entered was incorrect.";
        default:
          return defaultErrorMessage;
      }
    case MajorSyncErrorType.Internal:
      switch (error.minorType) {
        case MinorSyncErrorType.Unavailable:
        case MinorSyncErrorType.Unknown:
          return "We experienced an issue connecting to your bank.";
        default:
          return defaultErrorMessage;
      }
    default:
      return defaultErrorMessage;
  }
}
