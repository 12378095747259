import { z } from "zod";

export const identifyResponseSchema = z.object({
  profile_id: z.string().uuid(),
  user_id: z.string().uuid(),
  company_id: z.string().uuid(),
  is_verified: z.boolean(),
  is_authenticated: z.boolean(),
  is_authorized: z.boolean(),
});
export type IdentifyResponseSchema = z.infer<typeof identifyResponseSchema>;
