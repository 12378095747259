import { z } from "zod";

import { resendResponseSchema } from "./resendResponseSchema";

/**
 * @description Successful Response
 */
export const postResend200Schema = z.lazy(() => resendResponseSchema);
export type PostResend200Schema = z.infer<typeof postResend200Schema>;
/**
 * @description Successful Response
 */
export const postResendMutationResponseSchema = z.lazy(() => resendResponseSchema);
export type PostResendMutationResponseSchema = z.infer<typeof postResendMutationResponseSchema>;
