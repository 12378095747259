import { z } from "zod";

import { getResourcesResponseSchema } from "./getResourcesResponseSchema";
import { httpValidationErrorSchema } from "./httpValidationErrorSchema";

export const getResourcesPathParamsSchema = z.object({ sync_target_id: z.string().uuid() });
export type GetResourcesPathParamsSchema = z.infer<typeof getResourcesPathParamsSchema>;

export const getResourcesQueryParamsSchema = z.object({ prompt_id: z.string() });
export type GetResourcesQueryParamsSchema = z.infer<typeof getResourcesQueryParamsSchema>;
/**
 * @description Successful Response
 */
export const getResources200Schema = z.lazy(() => getResourcesResponseSchema);
export type GetResources200Schema = z.infer<typeof getResources200Schema>;
/**
 * @description Validation Error
 */
export const getResources422Schema = z.lazy(() => httpValidationErrorSchema);
export type GetResources422Schema = z.infer<typeof getResources422Schema>;
/**
 * @description Successful Response
 */
export const getResourcesQueryResponseSchema = z.lazy(() => getResourcesResponseSchema);
export type GetResourcesQueryResponseSchema = z.infer<typeof getResourcesQueryResponseSchema>;
