import { z } from "zod";

import { deleteResourceResponseSchema } from "./deleteResourceResponseSchema";
import { httpValidationErrorSchema } from "./httpValidationErrorSchema";

export const deleteResourcePathParamsSchema = z.object({
  sync_target_id: z.string().uuid(),
  resource_id: z.string().uuid(),
});
export type DeleteResourcePathParamsSchema = z.infer<typeof deleteResourcePathParamsSchema>;

export const deleteResourceQueryParamsSchema = z.object({ prompt_id: z.string() });
export type DeleteResourceQueryParamsSchema = z.infer<typeof deleteResourceQueryParamsSchema>;
/**
 * @description Successful Response
 */
export const deleteResource200Schema = z.lazy(() => deleteResourceResponseSchema);
export type DeleteResource200Schema = z.infer<typeof deleteResource200Schema>;
/**
 * @description Validation Error
 */
export const deleteResource422Schema = z.lazy(() => httpValidationErrorSchema);
export type DeleteResource422Schema = z.infer<typeof deleteResource422Schema>;
/**
 * @description Successful Response
 */
export const deleteResourceMutationResponseSchema = z.lazy(() => deleteResourceResponseSchema);
export type DeleteResourceMutationResponseSchema = z.infer<typeof deleteResourceMutationResponseSchema>;
