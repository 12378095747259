import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import GoBackButton from "@/components/Buttons/GoBackButton";
import ContentHeader from "@/components/ContentHeader";
import PlatformSearchSelector from "@/components/PlatformSearchSelector";
import { useBackButton } from "@/contexts/BackButtonContext";
import { PlatformSchema } from "@/gen/zod/platformSchema";
import useStateNavigate from "@/hooks/useStateNavigate";
import ContentBody from "@/pages/template/ContentBody";
import ContentFooter from "@/pages/template/ContentFooter";

export default function InstitutionSearch() {
  const { navigateNext, navigateBack } = useStateNavigate();
  const { setBackButtonControl } = useBackButton();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchText = searchParams.get("search") ?? "";

  function onSelect(item: PlatformSchema): void {
    navigateNext(`/platforms/${item.platform_id}`);
  }

  useEffect(() => {
    setBackButtonControl({ showReturnToClient: true, showBackButton: true, goBackCallback: navigateBack });
  }, [navigateBack, setBackButtonControl]);

  return (
    <>
      <ContentBody>
        <ContentHeader title="Select your bank" />
        <PlatformSearchSelector
          onSelect={onSelect}
          query={searchText}
        />
      </ContentBody>
      <ContentFooter>
        <div className="mt-4 flex w-full max-md:hidden">
          <GoBackButton onClick={() => navigateBack()} />
        </div>
      </ContentFooter>
    </>
  );
}
