import { z } from "zod";

import { getPlatformsResponseSchema } from "./getPlatformsResponseSchema";
import { httpValidationErrorSchema } from "./httpValidationErrorSchema";

export const getPlatformsQueryParamsSchema = z
  .object({
    text: z.union([z.string(), z.null()]).optional(),
    slug: z.union([z.array(z.string()), z.null()]).optional(),
    limit: z.number().int().default(20).optional(),
  })
  .optional();
export type GetPlatformsQueryParamsSchema = z.infer<typeof getPlatformsQueryParamsSchema>;
/**
 * @description Successful Response
 */
export const getPlatforms200Schema = z.lazy(() => getPlatformsResponseSchema);
export type GetPlatforms200Schema = z.infer<typeof getPlatforms200Schema>;
/**
 * @description Validation Error
 */
export const getPlatforms422Schema = z.lazy(() => httpValidationErrorSchema);
export type GetPlatforms422Schema = z.infer<typeof getPlatforms422Schema>;
/**
 * @description Successful Response
 */
export const getPlatformsQueryResponseSchema = z.lazy(() => getPlatformsResponseSchema);
export type GetPlatformsQueryResponseSchema = z.infer<typeof getPlatformsQueryResponseSchema>;
