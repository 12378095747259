import { InputHTMLAttributes, ReactElement, useState } from "react";

import { PromptOption } from "@/models/prompt";

interface SingleOptionProps extends InputHTMLAttributes<HTMLInputElement> {
  promptId: string;
  options: PromptOption[];
}

/**
 * Single Option component
 */
export function SingleOption({ promptId, options, ...props }: SingleOptionProps): ReactElement {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleChange = (value: string) => {
    setSelectedOption(value);
  };

  return (
    <>
      {options.map((option: PromptOption, i) => {
        const key = `${option.label}-${i}`;
        const isSelected = selectedOption === option.value;
        return (
          <label
            htmlFor={key}
            key={key}
            className={`mb-4 flex border p-4 ${isSelected ? "border-1 border-accent-fill" : "border-gray-300"}`}
          >
            <div className="items-top flex h-12 pt-1">
              <input
                name={promptId}
                id={key}
                type="radio"
                value={option.value}
                checked={isSelected}
                onChange={() => handleChange(option.value)}
                className="relative h-4 w-4 appearance-none rounded-full border-2 border-gray-300 bg-white transition duration-200 before:absolute before:left-1/2 before:top-1/2 before:h-0.5 before:w-0.5 before:-translate-x-1/2 before:-translate-y-1/2 before:transform before:rounded-full before:bg-white before:opacity-0 before:content-[''] checked:border-5 checked:border-accent-fill checked:before:opacity-100 focus:outline-none"
                {...props}
              />
            </div>
            <div className="ml-4 text-sm">
              <p className="mb-1 text-base font-medium text-blue-900">{option.label}</p>
              <p className="text-sm text-gray-700">{option.hint}</p>
            </div>
          </label>
        );
      })}
    </>
  );
}
