import MarkdownToJSX from "markdown-to-jsx";
import { ReactElement, ReactNode } from "react";

interface MarkdownProps {
  markdown: string;
}

const h1 = ({ children }: { children: ReactNode }) => (
  <h1 className="text-2xl font-semibold text-navy-500 md:text-4xl">{children}</h1>
);
const h2 = ({ children }: { children: ReactNode }) => (
  <h2 className="mt-2 text-2xl font-semibold tracking-tight text-gray-600 sm:text-3xl">{children}</h2>
);
const h3 = ({ children }: { children: ReactNode }) => (
  <h3 className="mt-2 text-xl font-light tracking-tight text-gray-900 sm:text-2xl">{children}</h3>
);
const p = ({ children }: { children: ReactNode }) => <p className="text-md md:text-lg">{children}</p>;
const a = ({ href, title, children }: { href: string; title: string; children: ReactNode }) => (
  <a
    href={href}
    title={title}
    className="text-md text-blue-500 underline md:text-lg"
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);
const div = ({ children }: { children: ReactNode }) => <div className="text-md md:text-lg">{children}</div>;
const span = ({ children }: { children: ReactNode }) => <span className="text-md md:text-lg">{children}</span>;
const strong = ({ children }: { children: ReactNode }) => (
  <span className="text-md font-semibold md:text-lg">{children}</span>
);
const ol = ({ children }: { children: ReactNode }) => <ol className="ml-6 list-decimal text-lg">{children}</ol>;
const ul = ({ children }: { children: ReactNode }) => <ul className="ml-6 list-disc text-sm md:text-lg">{children}</ul>;
const li = ({ children }: { children: ReactNode }) => <li className="my-2 last:mb-4">{children}</li>;
const td = ({ children }: { children: ReactNode }) => <td className="whitespace-nowrap pr-4 text-lg">{children}</td>;
const em = ({ children }: { children: ReactNode }) => (
  <span
    className="italic"
    style={{ fontVariationSettings: "'slnt' 10" }}
  >
    {children}
  </span>
);

export default function Markdown({ markdown }: MarkdownProps): ReactElement {
  return (
    <MarkdownToJSX
      options={{
        namedCodesToUnicode: {
          ldquot: "\u201C",
          rdquot: "\u201D",
        },
        overrides: {
          p: p,
          a: a,
          div: div,
          span: span,
          strong: strong,
          h1: h1,
          h2: h2,
          h3: h3,
          ol: ol,
          ul: ul,
          li: li,
          td: td,
          em: em,
        },
      }}
    >
      {markdown}
    </MarkdownToJSX>
  );
}
