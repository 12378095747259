import { z } from "zod";

export const finicityPlatformMetaSchema = z.object({
  aggregator_type: z.enum(["finicity"]).default("finicity").optional(),
  institution_id: z.number().int(),
  is_oauth: z.union([z.boolean(), z.null()]).default(false).optional(),
  user_key: z.union([z.array(z.any()), z.null()]).optional(),
  service_line_key: z.union([z.array(z.any()), z.null()]).optional(),
});
export type FinicityPlatformMetaSchema = z.infer<typeof finicityPlatformMetaSchema>;
