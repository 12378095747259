import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import { AuthState, AuthenticationStatus, InitToken, OAuthParams } from "@/interfaces";

interface AuthStore extends AuthState {
  setOAuthParams: (oauthParams: OAuthParams) => void;
  setInitTokenParams: (initTokenParams: InitToken) => void;
  setAuthenticationStatus: (authenticationStatus: AuthenticationStatus) => void;
  setHasInitialized: (hasInitialized: boolean) => void;
}

export const useAuthStore = create<AuthStore>()(
  devtools(
    persist(
      (set) => ({
        // Initial state.
        oauthParams: {} as OAuthParams,
        initTokenParams: {} as InitToken,
        hasInitialized: false,
        authenticationStatus: { emailAuthenticated: false, emailChallenged: null } as AuthenticationStatus,
        // Actions.
        setHasInitialized: (hasInitialized) => set({ hasInitialized }),
        setAuthenticationStatus: (authenticationStatus) => set({ authenticationStatus }),
        setOAuthParams: (oauthParams: OAuthParams) => set({ oauthParams }),
        setInitTokenParams: (initTokenParams: InitToken) => set({ initTokenParams }),
      }),
      { name: "auth" },
    ),
    {
      name: "connect",
      storage: sessionStorage,
    },
  ),
);

export default useAuthStore;
