import { z } from "zod";

export const promptTypeSchema = z.enum([
  "button",
  "numeric",
  "text",
  "password",
  "single-option",
  "multi-option",
  "files",
  "aggregator",
]);
export type PromptTypeSchema = z.infer<typeof promptTypeSchema>;
