import BaseModel from "@/models/base";
import { BaseSyncEventPayload } from "@/models/event";
import { UUID } from "@/types";

enum MessageType {
  Event = "event",
}

export enum SyncStage {
  Authenticate = "authn",
  CreateSubUser = "create-subuser",
  CheckUserPriv = "user-privilege",
  FetchAccounts = "accounts",
  FetchTransactions = "transactions",
  FetchCompanyIdentity = "identity",
  FetchRelatedEntities = "related-entities",
  FinalizeResult = "finalize",
}

export interface BaseSyncMessagePayload {
  eventId: UUID;
}

export class SyncEventMessage {
  messageType: MessageType.Event = MessageType.Event;
  messageId: UUID;
  syncTargetId: UUID;
  payload: BaseSyncEventPayload;

  constructor(props: { messageId: UUID; syncTargetId: UUID; payload: BaseSyncEventPayload }) {
    const { messageId, syncTargetId, payload } = props;
    const { eventId, eventType } = payload;
    if (messageId == null || syncTargetId == null || payload == null) {
      throw new Error("Missing required SyncEventMessageField");
    }

    if (eventType == null || eventId == null) {
      throw new Error("Missing required BaseSyncEventPayload field");
    }

    this.messageId = messageId;
    this.syncTargetId = syncTargetId;
    this.payload = payload;
  }

  static deserialize(message: string): SyncEventMessage {
    return new SyncEventMessage(BaseModel.deserialize<SyncEventMessage>(message));
  }

  static serialize(message: SyncEventMessage): string {
    return BaseModel.serialize(message as unknown as BaseModel);
  }
}
