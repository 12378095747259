import { useEffect } from "react";

interface CloseWarningProps {
  enabled?: boolean;
}

function CloseWarning({ enabled = true }: CloseWarningProps) {
  useEffect(() => {
    if (enabled) {
      function handleBeforeUnload(event: BeforeUnloadEvent) {
        // Almost all browsers use a default message for security reasons, so we don't bother setting a custom message.
        event.preventDefault();
        event.returnValue = "";
        return "";
      }
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }
  }, [enabled]);
  return null;
}

export default CloseWarning;
