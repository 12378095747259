import { z } from "zod";

import { getSyncResultResponseSchema } from "./getSyncResultResponseSchema";
import { httpValidationErrorSchema } from "./httpValidationErrorSchema";

export const getSyncResultPathParamsSchema = z.object({ sync_target_id: z.string().uuid() });
export type GetSyncResultPathParamsSchema = z.infer<typeof getSyncResultPathParamsSchema>;
/**
 * @description Successful Response
 */
export const getSyncResult200Schema = z.lazy(() => getSyncResultResponseSchema);
export type GetSyncResult200Schema = z.infer<typeof getSyncResult200Schema>;
/**
 * @description Validation Error
 */
export const getSyncResult422Schema = z.lazy(() => httpValidationErrorSchema);
export type GetSyncResult422Schema = z.infer<typeof getSyncResult422Schema>;
/**
 * @description Successful Response
 */
export const getSyncResultQueryResponseSchema = z.lazy(() => getSyncResultResponseSchema);
export type GetSyncResultQueryResponseSchema = z.infer<typeof getSyncResultQueryResponseSchema>;
