import { z } from "zod";

import { subuserStrategySchema } from "./subuserStrategySchema";

export const basicPlatformSchema = z.object({
  logo: z.union([z.string(), z.null()]),
  icon: z.union([z.string(), z.null()]),
  name: z.string(),
  subuser_strategy: z.lazy(() => subuserStrategySchema),
});
export type BasicPlatformSchema = z.infer<typeof basicPlatformSchema>;
