import * as Sentry from "@sentry/react";
import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

import App from "@/App";
import "@/index.css";

const SENTRY_REPLAY_ENVS = ["development", "production"];
const enableReplayInEnvs = (envs: string[]): boolean =>
  envs.includes(import.meta.env.VITE_REACT_APP_HOST_ENV as string) ? true : false;

Sentry.init({
  enabled: import.meta.env.VITE_REACT_APP_USE_SENTRY === "true",
  // https://basis-lf.sentry.io/projects/connect-ui/?project=4506396583460864
  dsn: "https://9499b4e9668ea238251574a27e3f24ec@o4506384299327488.ingest.sentry.io/4506396583460864",
  environment: import.meta.env.VITE_REACT_APP_HOST_ENV,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      mask: [".replay-redact"],
      networkDetailAllowUrls: [`${import.meta.env.VITE_REACT_APP_BASIS_API_URL ?? ""}/initialize`],
      networkCaptureBodies: true,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/\//, /.*usebasis\.co.*/],

  // Capture Replay for 100% of all sessions, plus for 100% of sessions with an error
  replaysSessionSampleRate: enableReplayInEnvs(SENTRY_REPLAY_ENVS) ? 1.0 : 0.0,
  replaysOnErrorSampleRate: enableReplayInEnvs(SENTRY_REPLAY_ENVS) ? 1.0 : 0.0,
});

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
