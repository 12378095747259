import { ReactElement } from "react";

import GlobalErrorScreen from "@/components/Exception/GlobalErrorScreen";

export default function AppErrorFallback(): ReactElement {
  return (
    <GlobalErrorScreen
      titleText="Something went wrong."
      body={<div className="text-xl">An unexpected error occurred. Please try again.</div>}
      showContact
    />
  );
}
