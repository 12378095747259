import { MajorSyncErrorType, MinorSyncErrorType, SyncError } from "@/models/error";
import { ErrorEventPayload, ReportEventPayload, ReportHint, StageCompleteEventPayload } from "@/models/event";
import { SyncStage } from "@/models/message";

export function handleStageCompleteEvent(payload: StageCompleteEventPayload, setError: (error: SyncError) => void) {
  const { stage, error } = payload;
  // send user back to login page if authentication failed. Save error message for display
  switch (stage[stage.length - 1]) {
    case SyncStage.Authenticate: {
      if (error) {
        setError(error);
        return true;
      }
      break;
    }
  }
  return false;
}

export function handleErrorEvent(payload: ErrorEventPayload, setError: (error: SyncError) => void) {
  const { error } = payload;
  if (
    // Prompt-related error
    error.majorType === MajorSyncErrorType.User &&
    error.minorType === MinorSyncErrorType.InvalidMFA
  ) {
    setError(error);
  }
}

export function handleReportEvent(payload: ReportEventPayload): boolean {
  const { hint } = payload;
  if (hint != null) {
    switch (hint) {
      case ReportHint.NoMoreInteractiveSteps:
        return true;
    }
  }
  return false;
}
