import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ENTRY_PATH } from "@/constants/constants";
import useAuthStore from "@/store/auth";
import useClientStore from "@/store/client";
import useUserStore from "@/store/user";

const useStateNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { profile } = useUserStore();
  const { authenticationStatus } = useAuthStore();

  const {
    config: { trust_email },
  } = useClientStore();

  const navigateNext = useCallback(
    (path?: string, replace = false) => {
      if (path) {
        navigate(path, { replace });
        return;
      }

      const hasServiceLines = profile.serviceLines.length > 0;
      const navigateToVerify = (replace = false) => navigate("/verify", { replace });
      const navigateToConnected = (replace = false) => navigate("/connected", { replace });
      const navigateToPlatformSelection = (replace = false) => navigate("/platforms", { replace });

      // Note: /platforms, /platforms/:platformId, and /sync manage their own navigation
      switch (location.pathname) {
        case ENTRY_PATH:
          if (trust_email || authenticationStatus.emailAuthenticated) {
            if (hasServiceLines) {
              navigateToConnected();
              return;
            }

            navigateToPlatformSelection();
            return;
          }

          navigateToVerify();
          return;

        case "/verify":
          if (authenticationStatus.emailAuthenticated) {
            if (hasServiceLines) {
              navigateToConnected(true);
              return;
            }

            navigateToPlatformSelection(true);
            return;
          }

          throw new Error("Attempted to continue without verifying.");

        case "/connected":
          navigateToPlatformSelection();
          return;

        case "/summary":
          navigateToPlatformSelection();
          return;

        default:
          throw new Error(`Unhandled route: ${location.pathname}`);
      }
    },
    [authenticationStatus.emailAuthenticated, trust_email, location.pathname, profile.serviceLines.length, navigate],
  );

  const navigateBack = useCallback(
    (count?: number) => {
      if (count !== undefined) {
        navigate(-count);
      } else {
        navigate(-1);
      }
    },
    [navigate],
  );

  return {
    navigateNext,
    navigateBack,
  };
};

export default useStateNavigation;
