import BaseModel from "@/models/base";

enum MajorSyncErrorType {
  User = "user",
  Account = "account",
  Institution = "institution",
  Internal = "internal",
}

enum MinorSyncErrorType {
  FailedAccountCreation = "failed-account-creation",
  InvalidCredentials = "invalid-credentials",
  InvalidMFA = "invalid-mfa",
  InsufficientPrivilege = "insufficient-privilege",
  Maintenance = "maintenance",
  MissingCredentials = "missing-credentials",
  MissingReports = "missing-reports",
  NotSupported = "not-supported",
  Unavailable = "unavailable",
  UnsupportedAccountType = "unsupported-account-type",
  Timeout = "timeout",
  Declined = "declined",
  Unknown = "unknown",
}

class SyncError extends BaseModel {
  majorType: MajorSyncErrorType;
  minorType: MinorSyncErrorType;
  reason: string | null = null;

  constructor(majorType: MajorSyncErrorType, minorType: MinorSyncErrorType, reason: string | null = null) {
    super();
    this.majorType = majorType;
    this.minorType = minorType;
    this.reason = reason;
  }

  public static unmarshal(obj: BaseModel): SyncError {
    const { majorType, minorType, reason } = obj;
    return new SyncError(majorType as MajorSyncErrorType, minorType as MinorSyncErrorType, reason as string | null);
  }
}

export { MajorSyncErrorType, MinorSyncErrorType, SyncError };
