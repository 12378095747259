import { z } from "zod";

import { httpValidationErrorSchema } from "./httpValidationErrorSchema";
import { oAuthHttpErrorSchema } from "./oAuthHttpErrorSchema";
import { oAuthInitializeRequestBodySchema } from "./oAuthInitializeRequestBodySchema";
import { oAuthInitializeResponseSchema } from "./oAuthInitializeResponseSchema";

/**
 * @description Successful Response
 */
export const postOAuthInitialize200Schema = z.lazy(() => oAuthInitializeResponseSchema);
export type PostOAuthInitialize200Schema = z.infer<typeof postOAuthInitialize200Schema>;
/**
 * @description Bad Request
 */
export const postOAuthInitialize400Schema = z.lazy(() => oAuthHttpErrorSchema);
export type PostOAuthInitialize400Schema = z.infer<typeof postOAuthInitialize400Schema>;
/**
 * @description Validation Error
 */
export const postOAuthInitialize422Schema = z.lazy(() => httpValidationErrorSchema);
export type PostOAuthInitialize422Schema = z.infer<typeof postOAuthInitialize422Schema>;

export const postOAuthInitializeMutationRequestSchema = z.lazy(() => oAuthInitializeRequestBodySchema);
export type PostOAuthInitializeMutationRequestSchema = z.infer<typeof postOAuthInitializeMutationRequestSchema>;
/**
 * @description Successful Response
 */
export const postOAuthInitializeMutationResponseSchema = z.lazy(() => oAuthInitializeResponseSchema);
export type PostOAuthInitializeMutationResponseSchema = z.infer<typeof postOAuthInitializeMutationResponseSchema>;
