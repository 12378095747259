import { MutableRefObject } from "react";

import { FileResource, Progress } from "@/interfaces";
import BaseModel from "@/models/base";

export interface UploadResourceResponse extends BaseModel {
  resourceId: string;
}

export function uploadResource(
  fileItem: FileResource,
  promptId: string,
  syncTargetId: string,
  refs: MutableRefObject<{ [key: string]: XMLHttpRequest }>,
  onProgress: (fileId: string, progress: Progress) => void,
): Promise<UploadResourceResponse> {
  return new Promise((resolve, reject) => {
    if (!fileItem.data || !fileItem.file_id) return reject();
    if (!fileItem.data.type.match(/(pdf|png|jpeg)/)) {
      return reject("Unsupported file type");
    }
    const formData = new FormData();
    formData.append("resource", fileItem.data);
    formData.append("prompt_id", promptId);

    const xhr = (refs.current[fileItem.file_id] = new XMLHttpRequest());
    xhr.withCredentials = true;

    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        const progress = { current: event.loaded, total: event.total };
        onProgress(fileItem.file_id, progress);
      }
    });

    xhr.addEventListener("load", () => {
      try {
        resolve(BaseModel.deserialize<UploadResourceResponse>(xhr.responseText));
      } catch {
        reject("Upload failed");
      }
    });

    xhr.addEventListener("error", () => {
      reject("Upload failed");
    });

    xhr.open("POST", `${import.meta.env.VITE_REACT_APP_BASIS_API_URL ?? ""}/sync/${syncTargetId}/resources`);
    xhr.send(formData);
  });
}
