import { z } from "zod";

import { getSyncResultsResponseSchema } from "./getSyncResultsResponseSchema";

/**
 * @description Successful Response
 */
export const getSyncResults200Schema = z.lazy(() => getSyncResultsResponseSchema);
export type GetSyncResults200Schema = z.infer<typeof getSyncResults200Schema>;
/**
 * @description Successful Response
 */
export const getSyncResultsQueryResponseSchema = z.lazy(() => getSyncResultsResponseSchema);
export type GetSyncResultsQueryResponseSchema = z.infer<typeof getSyncResultsQueryResponseSchema>;
