import { z } from "zod";

import { bodyPostResourceSchema } from "./bodyPostResourceSchema";
import { httpValidationErrorSchema } from "./httpValidationErrorSchema";
import { postResourceResponseSchema } from "./postResourceResponseSchema";

export const postResourcePathParamsSchema = z.object({ sync_target_id: z.string().uuid() });
export type PostResourcePathParamsSchema = z.infer<typeof postResourcePathParamsSchema>;
/**
 * @description Successful Response
 */
export const postResource200Schema = z.lazy(() => postResourceResponseSchema);
export type PostResource200Schema = z.infer<typeof postResource200Schema>;
/**
 * @description Validation Error
 */
export const postResource422Schema = z.lazy(() => httpValidationErrorSchema);
export type PostResource422Schema = z.infer<typeof postResource422Schema>;

export const postResourceMutationRequestSchema = z.lazy(() => bodyPostResourceSchema);
export type PostResourceMutationRequestSchema = z.infer<typeof postResourceMutationRequestSchema>;
/**
 * @description Successful Response
 */
export const postResourceMutationResponseSchema = z.lazy(() => postResourceResponseSchema);
export type PostResourceMutationResponseSchema = z.infer<typeof postResourceMutationResponseSchema>;
