import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { ReactElement } from "react";

import GlobalErrorScreen from "@/components/Exception/GlobalErrorScreen";

function NotFound(): ReactElement {
  return (
    <GlobalErrorScreen
      titleText="Page not found."
      icon={<ExclamationTriangleIcon className="mt-1 h-10 w-10 fill-amber-500 stroke-white" />}
      body={<div className="text-xl">Please return to the previous page and try again.</div>}
      showContact
      showGoBack
    />
  );
}

export default NotFound;
