import { z } from "zod";

import { getClientViewSchema } from "./getClientViewSchema";

/**
 * @description Successful Response
 */
export const getClient200Schema = z.lazy(() => getClientViewSchema);
export type GetClient200Schema = z.infer<typeof getClient200Schema>;
/**
 * @description Successful Response
 */
export const getClientQueryResponseSchema = z.lazy(() => getClientViewSchema);
export type GetClientQueryResponseSchema = z.infer<typeof getClientQueryResponseSchema>;
