import { z } from "zod";

import { basicAccountSchema } from "./basicAccountSchema";
import { basicPlatformSchema } from "./basicPlatformSchema";

export const basicServiceLineSchema = z.object({
  slug: z.string(),
  platform: z.lazy(() => basicPlatformSchema),
  accounts: z.array(z.lazy(() => basicAccountSchema)),
});
export type BasicServiceLineSchema = z.infer<typeof basicServiceLineSchema>;
