import { z } from "zod";

import { syncAuthorizationSourceSchema } from "./syncAuthorizationSourceSchema";

export const basicAuthorizationSchema = z.object({
  slug: z.string(),
  source: z.lazy(() => syncAuthorizationSourceSchema),
  create_time: z.string().datetime({ local: true }),
});
export type BasicAuthorizationSchema = z.infer<typeof basicAuthorizationSchema>;
