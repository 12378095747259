import PlatformIcon from "@/components/PlatformIcon";
import { BasicAccountSchema } from "@/gen/zod/basicAccountSchema";
import { BasicServiceLineSchema } from "@/gen/zod/basicServiceLineSchema";

function ConnectedServiceLine({ serviceLine }: { serviceLine: BasicServiceLineSchema }) {
  const getAccountRepr = (idx: number, acct: BasicAccountSchema): string =>
    [acct.display_name, acct.account_mask && `****${acct.account_mask}`].filter((e) => e).join(" ") || `Account ${idx}`;
  return (
    <div className="flex items-center justify-between border-1 border-navy-100 p-2 text-gray-700 md:p-4">
      <div className="flex flex-row items-center gap-x-4">
        <div className="mt-1 self-start">
          <PlatformIcon icon={serviceLine.platform.icon} />
        </div>
        <div className="overflow-hidden">
          <div className="mb-1 text-lg font-bold md:mb-2">{serviceLine.platform.name}</div>
          {serviceLine.accounts.length > 0 ? (
            <>
              <div className="mb-1 text-xs font-light">Accounts</div>
              {serviceLine.accounts.map((a, i) => (
                <div
                  key={i}
                  className="md:text-md text-xs font-light"
                >
                  {getAccountRepr(i, a)}
                </div>
              ))}
            </>
          ) : (
            <div className="md:text-md text-xs font-light">No accounts found.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConnectedServiceLine;
