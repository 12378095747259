import { z } from "zod";

import { finicityPlatformMetaSchema } from "./finicityPlatformMetaSchema";
import { promptSchema } from "./promptSchema";

export const getPlatformResponseSchema = z.object({
  platform_id: z.string().uuid(),
  display_name: z.string(),
  platform_name: z.string(),
  logo: z.union([z.string(), z.null()]),
  icon: z.union([z.string(), z.null()]),
  homepage_url: z.string(),
  slug: z.string(),
  aggregator: z.union([z.lazy(() => finicityPlatformMetaSchema), z.null()]),
  credential_prompts: z.array(z.lazy(() => promptSchema)),
  subuser_strategy: z.string(),
});
export type GetPlatformResponseSchema = z.infer<typeof getPlatformResponseSchema>;
